import React from 'react';
import classnames from 'classnames';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import Tooltip from '../../../../components/Tooltip';
import Dropdown from '../../../../components/Dropdown';
import { MyLink } from '../../../../components/MyLink';
import { APP_LINKS } from '../../../../helpers/links';
import { ExampleList } from './ExampleList';
import { ExampleTable } from './ExampleTable';
import { IncreasedIncomeWarning } from './WarningIncome';

import {
  // EXAMPLE_A_DATA_12,
  // EXAMPLE_A_DATA_18,
  // EXAMPLE_A_DATA_24,
  // EXAMPLE_B_DATA_12,
  // EXAMPLE_B_DATA_18,
  // EXAMPLE_B_DATA_24,
  GroupAExample,
  GroupAExampleFor1,
  GroupBExample,
} from './tableData';

// import A_REGULAR_REPAYMENT from './assets/a_regular_repayment_scenario.svg';
// import A_LAST_MONTH from './assets/a_last_month.svg';
// import A_OPTION_1 from './assets/a_option_1.svg';
// import A_OPTION_2 from './assets/a_option_2.svg';
// import A_OPTION_3 from './assets/a_option_3.svg';
// import B_REGULAR_REPAYMENT from './assets/b_regular_scenario.svg';
// import B_OPTION_1 from './assets/b_option_1.svg';
// import B_OPTION_2 from './assets/b_option_2.svg';
// import B_OPTION_3 from './assets/b_option_3.svg';
import s from './index.module.scss';

export const GROUP_DETAIL = {
  [LOAN_GROUPS.conservative.id]: innerWidth => ({
    title: 'Group A',
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-26-32 f-500">Loan terms</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  ' f-16-24 f-400'
                )}
              >
                Target annual return
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.tooltip, 'f-16 f-400')}>
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(
                s.page__annual_return_value,
                'f-28-32 f-500'
              )}
            >
              Up to 12.4%
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(
              s.page__nominal_return_title,
              'f-16-24 f-400'
            )}
          >
            Nominal return
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400'
                )}
              >
                for the standard 6-month term
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">up to 1% per month</div>
                <div className="f-16-24 f-400">
                  on the outstanding loan principal paid at the end of the term
                </div>
              </div>
            </div>
            {/* <div className={s.page__nominal_return_item}> */}
            {/*   <div */}
            {/*     className={classnames( */}
            {/*       s.page__nominal_return_item_desc, */}
            {/*       'f-16-24 f-400' */}
            {/*     )} */}
            {/*   > */}
            {/*     if funds are used by a brand beyond 6 months (up to 24 months) */}
            {/*   </div> */}
            {/*   <div className={s.page__nominal_return_item_circle}> */}
            {/*     <div className="f-28-32 f-500">+ up to 4.4% extra flat fee</div> */}
            {/*     <div className="f-16-24 f-400"> */}
            {/*       based on loan repayment timing */}
            {/*     </div> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
        </div>

        <ul className={s.page__repayments_list} style={{ marginTop: '30px' }}>
          <li>
            <div className="f-16-24 f-400">
              Basic income - 0.75% per month is accrued on the outstanding loan
              amount.
            </div>
          </li>
          <li>
            <div className="f-16-24 f-400">
              Increased income - 1% per month on the outstanding loan amount.
              Accrued with monthly replenishment and investments not less than
              €100 per month during the all loan repayment period.
            </div>
          </li>
          <li>
            <div className="f-16-24 f-400">
              Total return is accrued on a monthly basis but is paid at the end
              of the loan term.
            </div>
          </li>
        </ul>
      </section>
    ),
    fundsUsageSection: (
      <section className={s.page__usage}>
        <h2 className={classnames(s.page__usage_title, 'f-26-32 f-500')}>
          Brands selection and funds usage
        </h2>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          Loans are issued to a pre-selected group of consumer goods brands
          based in the UK or continental Europe. To qualify, each company must
          demonstrate strong consumer affection for the product, have a
          qualified co-founders team, and not be in a distressed financial
          situation.
        </p>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          Funds are used by brands for working capital (purchase of inventory,
          packaging materials and the like).
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-26-32 f-500">Investor funds protection</h2>
        <p className="f-16-24 f-400">
          Loans of group A – Senior loans – are the first ones to be repaid. So
          if a business fails, investors in loans A have the highest chances to
          be repaid.
        </p>

        <div className={s.page__protection_content}>
          {[
            {
              label: 'Regular monthly repayments',
              content: (
                <div className="f-16-24 f-400">
                  Investors start receiving repayments from the first month
                  after the loan is issued, and with each month the total amount
                  of capital at risk goes down.
                </div>
              ),
            },
            {
              label: 'Co-founder team guarantees',
              content: (
                <div className="f-16-24 f-400">
                  Each co-founder guarantees to repay up to 40% of the loan to
                  their business with personal lifetime income. So jointly two
                  or three co-founders guarantee 80% or even 100%.
                </div>
              ),
            },
            {
              label: 'First loss capital',
              content: (
                <div className="f-16-24 f-400">
                  Investors in loans B contribute 15% of total funding for each
                  batch but are only paid back after all investors in loans A
                  are repaid. Simply put, investors A are not affected in any
                  way in case up to 15% of all funds are lost.
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle
              defaultShow
            >
              <div className={s.page__protection_dropdown}>{item.content}</div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-26-32 f-500">Loan repayments</h2>
        <p className="f-16-24 f-400">
          Monthly loan repayments are transferred to your Scramble cash account
          on the 5th day, the month after the loan is issued.
        </p>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18-24 f-500">Regular repayment scenario:</h4>
          {/* <img */}
          {/*   src={A_REGULAR_REPAYMENT} */}
          {/*   alt="Group A" */}
          {/*   style={{ */}
          {/*     maxWidth: '500px', */}
          {/*     margin: '40px auto', */}
          {/*     display: 'block', */}
          {/*     width: '100%', */}
          {/*   }} */}
          {/*   className={s.page__repayments_image} */}
          {/* /> */}

          <ul className={s.page__repayments_list}>
            <li>
              <div className="f-16-24 f-500">
                From the end of the 1st month to the end of the 5th month
              </div>
              <div className="f-16-24 f-400">
                During the first 5 months, you get monthly loan principal
                repayments. The amount of monthly repayments depends on the
                round participants and can vary from 2.35% to 9.41%.
              </div>
            </li>
            <li>
              <div className="f-16-24 f-500">At the end of the 6th month</div>
              <div className="f-16-24 f-400">
                At the end of the 6th month, you get the remaining amount of the
                loan principal and a flat fee.
              </div>
            </li>
          </ul>
          <Dropdown
            label={
              <span className="f-18 f-500">
                A regular repayment schedule example for Basic 0.75% scenario
              </span>
            }
            className={s.page__example}
            defaultShow
            disableToggle
          >
            <>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>Loan amount</div>
                    <div className="f-500">
                      <b>€1’000</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>Origination date</div>
                    <div className="f-500">
                      <b>15.01.2024</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right}>
                  {/* <DownloadOptions */}
                  {/*   links={[ */}
                  {/*     { format: 'pdf', link: '#' }, */}
                  {/*     { format: 'xlsx', link: '#' }, */}
                  {/*     { format: 'csv', link: '#' }, */}
                  {/*   ]} */}
                  {/* /> */}
                </div>
              </div>
              <div className="f-16-24 f-400" style={{ marginTop: '20px' }}>
                Since the amount of monthly repayments depends on the round
                participants and can vary, below is an example of a possible
                repayment scenario.
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExample} />
              ) : (
                <ExampleTable data={GroupAExample} />
              )}
            </>
          </Dropdown>

          <IncreasedIncomeWarning
            amount={12.77}
            className={s.page__example_warning}
          />

          <Dropdown
            label={
              <span className="f-18 f-400 underline">
                Check a repayment schedule example for Increased 1% scenario
              </span>
            }
            className={s.page__example}
            defaultShow={false}
          >
            <>
              <div className="f-18 f-500" style={{ marginTop: '40px' }}>
                A regular repayment schedule example for 1% scenario
              </div>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>Loan amount</div>
                    <div className="f-500">
                      <b>€1’000</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>Origination date</div>
                    <div className="f-500">
                      <b>15.01.2024</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right}>
                  {/* <DownloadOptions */}
                  {/*   links={[ */}
                  {/*     { format: 'pdf', link: '#' }, */}
                  {/*     { format: 'xlsx', link: '#' }, */}
                  {/*     { format: 'csv', link: '#' }, */}
                  {/*   ]} */}
                  {/* /> */}
                </div>
              </div>
              <div className="f-16-24 f-400" style={{ marginTop: '20px' }}>
                Since the amount of monthly repayments depends on the round
                participants and can vary, below is an example of a possible
                repayment scenario.
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupAExampleFor1} />
              ) : (
                <ExampleTable data={GroupAExampleFor1} />
              )}
            </>
          </Dropdown>
        </div>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-500">
            A brand can move repayments to the last month
          </h4>
          <p className="f-16-24 f-400">
            Each brand can also move a monthly repayment to the last month of
            the current loan term.
          </p>
          <p className="f-16-24 f-400">
            What happens if a startup moves a monthly repayment to the last
            month of the current loan term?
          </p>
          <p className="f-16-24 f-400">
            For example, if a startup skips a repayment in the second month,
            then the repayment will automatically be moved to the last 6th
            month.
          </p>
          {/* <img */}
          {/*   src={A_LAST_MONTH} */}
          {/*   alt="A startup can move repayments to the last month" */}
          {/*   style={{ */}
          {/*     maxWidth: '500px', */}
          {/*     margin: '0 auto', */}
          {/*     display: 'block', */}
          {/*     width: '100%', */}
          {/*   }} */}
          {/* /> */}
        </div>

        {/* <div className={s.page__repayments_chapter}> */}
        {/*   <h4 className="f-18 f-500"> */}
        {/*     A startup can extend a repayment schedule */}
        {/*   </h4> */}
        {/*   <p className="f-16-24 f-400"> */}
        {/*     Each startup can extend the repayment period of the loan to 12, 18 */}
        {/*     or 24 months. */}
        {/*   </p> */}

        {/*   <Dropdown */}
        {/*     label={ */}
        {/*       <span className="f-16 f-500"> */}
        {/*         Option 1: A startup extends a loan term to 12 months */}
        {/*       </span> */}
        {/*     } */}
        {/*     defaultShow={false} */}
        {/*     className={s.page__repayments_option} */}
        {/*   > */}
        {/*     <div className="f-16 f-400" style={{ marginTop: '10px' }}> */}
        {/*       /!* eslint-disable-next-line react/no-unescaped-entities *!/ */}
        {/*       What happens if a startup doesn't pay off the remaining part of */}
        {/*       the loan principal in the 6th month? */}
        {/*       <br /> */}
        {/*       Then the repayment schedule is automatically extended for an */}
        {/*       additional 6 months. */}
        {/*     </div> */}
        {/*     <img */}
        {/*       src={A_OPTION_1} */}
        {/*       alt="Option 1" */}
        {/*       className={s.page__repayments_image} */}
        {/*       style={{ */}
        {/*         margin: '30px auto 40px', */}
        {/*         display: 'block', */}
        {/*         width: '100%', */}
        {/*       }} */}
        {/*     /> */}

        {/*     <ul className={s.page__repayments_list}> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 6th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           You will receive an additional 3% flat fee of the loan amount, */}
        {/*           as well as a 6.6% monthly loan principal repayment. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">From 7th to 11th months</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           Then from 7th to 11th months, you get 4.7% of the loan */}
        {/*           principal each month. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 12th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           In the 12th month you get the remaining 44.9% of the loan */}
        {/*           principal. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*     </ul> */}

        {/*     <Dropdown */}
        {/*       label={ */}
        {/*         <span className="f-16 f-500"> */}
        {/*           A 12-month repayment schedule example */}
        {/*         </span> */}
        {/*       } */}
        {/*       defaultShow */}
        {/*       disableToggle */}
        {/*       className={s.page__repayments_dropdown} */}
        {/*     > */}
        {/*       <> */}
        {/*         <div className={s.page__example_summary}> */}
        {/*           <div className={s.page__example_summary_left}> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Loan amount</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>€1’000</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Origination date</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>05.01.2022</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*           </div> */}
        {/*           <div className={s.page__example_summary_right}> */}
        {/*             /!* <DownloadOptions *!/ */}
        {/*             /!*   links={[ *!/ */}
        {/*             /!*     { format: 'pdf', link: '#' }, *!/ */}
        {/*             /!*     { format: 'xlsx', link: '#' }, *!/ */}
        {/*             /!*     { format: 'csv', link: '#' }, *!/ */}
        {/*             /!*   ]} *!/ */}
        {/*             /!* /> *!/ */}
        {/*           </div> */}
        {/*         </div> */}
        {/*         <div className={s.page__example_data}> */}
        {/*           {window.innerWidth < 992 ? ( */}
        {/*             <ExampleList data={EXAMPLE_A_DATA_12} /> */}
        {/*           ) : ( */}
        {/*             <ExampleTable data={EXAMPLE_A_DATA_12} /> */}
        {/*           )} */}
        {/*         </div> */}
        {/*       </> */}
        {/*     </Dropdown> */}
        {/*   </Dropdown> */}

        {/*   <Dropdown */}
        {/*     label={ */}
        {/*       <span className="f-16 f-500"> */}
        {/*         Option 2: A startup extends a loan term to 18 months */}
        {/*       </span> */}
        {/*     } */}
        {/*     defaultShow={false} */}
        {/*     className={s.page__repayments_option} */}
        {/*   > */}
        {/*     <div className="f-16 f-400" style={{ marginTop: '10px' }}> */}
        {/*       What happens if a startup doesn&apos;t pay off the remaining part */}
        {/*       of the loan principal in the 12th month? */}
        {/*       <br /> */}
        {/*       Then the repayment schedule is automatically extended for an */}
        {/*       additional 6 months. */}
        {/*     </div> */}
        {/*     <img */}
        {/*       src={A_OPTION_2} */}
        {/*       alt="Option 2" */}
        {/*       className={s.page__repayments_image} */}
        {/*       style={{ */}
        {/*         margin: '30px auto 40px', */}
        {/*         display: 'block', */}
        {/*         width: '100%', */}
        {/*       }} */}
        {/*     /> */}

        {/*     <ul className={s.page__repayments_list}> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 12th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           You will receive an additional 1.4% flat fee of the loan */}
        {/*           amount, as well as a 7.5% monthly loan principal repayment. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">From 13th to 17th months</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           Then from 13th to 17th months, you get 4.7% of the loan */}
        {/*           principal each month. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 18th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           In the 18th month, you get the remaining 13.9% of the loan */}
        {/*           principal. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*     </ul> */}

        {/*     <Dropdown */}
        {/*       label={ */}
        {/*         <span className="f-16 f-500"> */}
        {/*           An 18-month repayment schedule example */}
        {/*         </span> */}
        {/*       } */}
        {/*       defaultShow */}
        {/*       disableToggle */}
        {/*       className={s.page__repayments_dropdown} */}
        {/*     > */}
        {/*       <> */}
        {/*         <div className={s.page__example_summary}> */}
        {/*           <div className={s.page__example_summary_left}> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Loan amount</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>€1’000</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Origination date</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>05.01.2022</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*           </div> */}
        {/*           <div className={s.page__example_summary_right}> */}
        {/*             /!* <DownloadOptions *!/ */}
        {/*             /!*   links={[ *!/ */}
        {/*             /!*     { format: 'pdf', link: '#' }, *!/ */}
        {/*             /!*     { format: 'xlsx', link: '#' }, *!/ */}
        {/*             /!*     { format: 'csv', link: '#' }, *!/ */}
        {/*             /!*   ]} *!/ */}
        {/*             /!* /> *!/ */}
        {/*           </div> */}
        {/*         </div> */}
        {/*         <div className={s.page__example_data}> */}
        {/*           {window.innerWidth < 992 ? ( */}
        {/*             <ExampleList data={EXAMPLE_A_DATA_18} /> */}
        {/*           ) : ( */}
        {/*             <ExampleTable data={EXAMPLE_A_DATA_18} /> */}
        {/*           )} */}
        {/*         </div> */}
        {/*       </> */}
        {/*     </Dropdown> */}
        {/*   </Dropdown> */}

        {/*   <Dropdown */}
        {/*     label={ */}
        {/*       <span className="f-16 f-500"> */}
        {/*         Option 3: A startup extends a loan term to 24 months */}
        {/*       </span> */}
        {/*     } */}
        {/*     defaultShow={false} */}
        {/*     className={s.page__repayments_option} */}
        {/*   > */}
        {/*     <div className="f-16 f-400" style={{ marginTop: '10px' }}> */}
        {/*       What happens if a startup doesn&apos;t pay off the remaining part */}
        {/*       of the loan principal in the 18th month? */}
        {/*       <br /> */}
        {/*       Then the repayment schedule is automatically extended for an */}
        {/*       additional 6 months. */}
        {/*     </div> */}
        {/*     <img */}
        {/*       src={A_OPTION_3} */}
        {/*       alt="Option 3" */}
        {/*       className={s.page__repayments_image} */}
        {/*       style={{ */}
        {/*         margin: '30px auto 40px', */}
        {/*         display: 'block', */}
        {/*         width: '100%', */}
        {/*       }} */}
        {/*     /> */}

        {/*     <ul className={s.page__repayments_list}> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 18th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           You will receive an 8.5% monthly loan principal repayment. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 19th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           You get 4.7% of the loan principal. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 20th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           In the 20th month, you get the remaining 0.7% of the loan */}
        {/*           principal. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*     </ul> */}

        {/*     <Dropdown */}
        {/*       label={ */}
        {/*         <span className="f-16 f-500"> */}
        {/*           A 24-month repayment schedule example */}
        {/*         </span> */}
        {/*       } */}
        {/*       defaultShow */}
        {/*       disableToggle */}
        {/*       className={s.page__repayments_dropdown} */}
        {/*     > */}
        {/*       <> */}
        {/*         <div className={s.page__example_summary}> */}
        {/*           <div className={s.page__example_summary_left}> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Loan amount</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>€1’000</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Origination date</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>05.01.2022</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*           </div> */}
        {/*           <div className={s.page__example_summary_right}> */}
        {/*             /!* <DownloadOptions *!/ */}
        {/*             /!*   links={[ *!/ */}
        {/*             /!*     { format: 'pdf', link: '#' }, *!/ */}
        {/*             /!*     { format: 'xlsx', link: '#' }, *!/ */}
        {/*             /!*     { format: 'csv', link: '#' }, *!/ */}
        {/*             /!*   ]} *!/ */}
        {/*             /!* /> *!/ */}
        {/*           </div> */}
        {/*         </div> */}
        {/*         <div className={s.page__example_data}> */}
        {/*           {window.innerWidth < 992 ? ( */}
        {/*             <ExampleList data={EXAMPLE_A_DATA_24} /> */}
        {/*           ) : ( */}
        {/*             <ExampleTable data={EXAMPLE_A_DATA_24} /> */}
        {/*           )} */}
        {/*         </div> */}
        {/*       </> */}
        {/*     </Dropdown> */}
        {/*   </Dropdown> */}
        {/* </div> */}
      </section>
    ),
    agreementSection: (
      <section className={s.page__agreement}>
        <h2 className="f-24 f-500">Loan agreements</h2>
        <p className="f-16 f-400">
          When you set up your investing settings, you accept the terms of the
          Financing Agreement or the Annex to Financing Agreement and the
          Assignment Agreement. All documents for each created loan are
          available for download inside group A loan cards on the{' '}
          <MyLink href={APP_LINKS.loans}>Loans page</MyLink>.
        </p>
        {/* <MyLink */}
        {/*   isExternal */}
        {/*   href={'APP_LINKS.unsubordinatedFinancialAgreement'} */}
        {/*   className={s.page__agreement_link} */}
        {/* > */}
        {/*   Download Financing Agreement (pdf) */}
        {/* </MyLink> */}
        {/* <MyLink */}
        {/*   isExternal */}
        {/*   href={'APP_LINKS.unsubordinatedFinancialAgreement'} */}
        {/*   className={s.page__agreement_link} */}
        {/* > */}
        {/*   Download Annex to Financing Agreement (pdf) */}
        {/* </MyLink> */}
        {/* <MyLink */}
        {/*   isExternal */}
        {/*   href={'APP_LINKS.unsubordinatedFinancialAgreement'} */}
        {/*   className={s.page__agreement_link} */}
        {/* > */}
        {/*   Download Assignment Agreement (pdf) */}
        {/* </MyLink> */}
      </section>
    ),
  }),

  [LOAN_GROUPS.moderate.id]: (data, innerWidth) => ({
    title: 'Group B',
    termsSection: (
      <section className={s.page__terms}>
        <h2 className="f-26-32 f-500">Loan terms</h2>
        <div className={s.page__first_row}>
          <div className={s.page__annual_return}>
            <div className={s.page__annual_return_header}>
              <div
                className={classnames(
                  s.page__annual_return_title,
                  'f-16-24 f-400'
                )}
              >
                Target annual return
              </div>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.tooltip, 'f-16 f-400')}>
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <div
              className={classnames(
                s.page__annual_return_value,
                'f-28-32 f-500'
              )}
            >
              Up to 25%
            </div>
          </div>
        </div>
        <div className={s.page__second_row}>
          <div
            className={classnames(
              s.page__nominal_return_title,
              'f-16-24 f-400'
            )}
          >
            Nominal return
          </div>
          <div className={s.page__nominal_return}>
            <div className={s.page__nominal_return_item}>
              <div
                className={classnames(
                  s.page__nominal_return_item_desc,
                  'f-16-24 f-400'
                )}
              >
                for the standard 6-month term:
              </div>
              <div className={s.page__nominal_return_item_circle}>
                <div className="f-28-32 f-500">9% flat fee</div>
                <div className="f-16-24 f-400">paid at the end of the term</div>
              </div>
            </div>
            {/* <div className={s.page__nominal_return_item}> */}
            {/*   <div */}
            {/*     className={classnames( */}
            {/*       s.page__nominal_return_item_desc, */}
            {/*       'f-16-24 f-400' */}
            {/*     )} */}
            {/*   > */}
            {/*     if funds are used by a startup beyond 6 months (up to 24 months) */}
            {/*   </div> */}
            {/*   <div className={s.page__nominal_return_item_circle}> */}
            {/*     <div className="f-28-32 f-500">+ up to 49% extra flat fee</div> */}
            {/*     <div className="f-16-24 f-400"> */}
            {/*       based on loan repayment timing */}
            {/*     </div> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
        </div>
      </section>
    ),
    fundsUsageSection: (
      <section className={s.page__usage}>
        <h2 className={(s.page__usage_title, 'f-26-32 f-500')}>
          Brands selection and funds usage
        </h2>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          Loans issued to a pre-selected group of consumer goods brands based in
          the UK or continental Europe. To qualify, each company must
          demonstrate strong consumer affection for the product, have a
          qualified co-founders team, and not be in a distressed financial
          situation.
        </p>
        <p className={classnames(s.page__usage_desc, 'f-16-24 f-400')}>
          Funds are used by brands for working capital (purchase of inventory,
          packaging materials and the like).
        </p>
      </section>
    ),
    fundsProtectionSection: (
      <section className={s.page__protection}>
        <h2 className="f-26-32 f-500">Investor funds protection</h2>
        <p className="f-16-24 f-400">
          Loans of group B – Junior loans – are the last ones to be repaid. So
          if a business fails, investors in loans A must be repaid in full
          before investors in loans B get their money back.
        </p>

        <div className={s.page__protection_content}>
          {[
            {
              label: 'Co-founder team guarantees',
              content: (
                <div className="f-16-24 f-400">
                  Each co-founder guarantees to repay up to 40% of the loan to
                  their business with personal lifetime income. So jointly two
                  or three co-founders guarantee 80% or even 100%.
                </div>
              ),
            },
          ].map((item, idx) => (
            <Dropdown
              key={item.label}
              label={<span className="f-20 f-500">{item.label}</span>}
              data-index={`0${idx + 1}`}
              disableToggle={innerWidth > 992}
              defaultShow
            >
              <div className={s.page__protection_dropdown}>{item.content}</div>
            </Dropdown>
          ))}
        </div>
      </section>
    ),
    repaymentSection: (
      <section className={s.page__repayments}>
        <h2 className="f-26-32 f-500">Loan repayments</h2>
        <p className="f-16-24 f-400">
          Monthly loan repayments are transferred to your Scramble cash account
          on the 5th day, the month after the loan is issued.
        </p>

        <div className={s.page__repayments_chapter}>
          <h4 className="f-18 f-400">Regular repayment scenario:</h4>
          {/* <img */}
          {/*   src={B_REGULAR_REPAYMENT} */}
          {/*   alt="Group B" */}
          {/*   className={s.page__repayments_image} */}
          {/*   style={{ */}
          {/*     maxWidth: '400px', */}
          {/*     margin: '30px auto 40px', */}
          {/*     display: 'block', */}
          {/*     width: '100%', */}
          {/*   }} */}
          {/* /> */}
          <ul className={s.page__repayments_list}>
            <li>
              <div className="f-16-24 f-500">At end of the 6th month</div>
              <div className="f-16-24 f-400">
                At the end of the 6th month, you get 100% of the loan principal
                and a 9% flat fee.
              </div>
            </li>
          </ul>

          <Dropdown
            label={
              <span className="f-18 f-500">
                A regular repayment schedule example
              </span>
            }
            className={s.page__example}
            defaultShow
            disableToggle
          >
            <>
              <div className={s.page__example_summary}>
                <div className={s.page__example_summary_left}>
                  <div className="f-16">
                    <div>Loan amount</div>
                    <div className="f-500">
                      <b>€1’500</b>
                    </div>
                  </div>
                  <div className="f-16">
                    <div>Origination date</div>
                    <div className="f-500">
                      <b>15.01.2024</b>
                    </div>
                  </div>
                </div>
                <div className={s.page__example_summary_right}>
                  {/* <DownloadOptions */}
                  {/*   links={[ */}
                  {/*     { format: 'pdf', link: '#' }, */}
                  {/*     { format: 'xlsx', link: '#' }, */}
                  {/*     { format: 'csv', link: '#' }, */}
                  {/*   ]} */}
                  {/* /> */}
                </div>
              </div>
              {window.innerWidth < 992 ? (
                <ExampleList data={GroupBExample} />
              ) : (
                <ExampleTable data={GroupBExample} />
              )}
            </>
          </Dropdown>
        </div>

        {/* <div className={s.page__repayments_chapter}> */}
        {/*   <h4 className="f-18 f-500"> */}
        {/*     A brand can extend a repayment schedule */}
        {/*   </h4> */}
        {/*   <p className="f-16-24 f-400"> */}
        {/*     Each brand can extend the repayment period of the loan to 12, 18 or */}
        {/*     24 months. */}
        {/*   </p> */}

        {/*   <Dropdown */}
        {/*     label={ */}
        {/*       <span className="f-16 f-500"> */}
        {/*         Option 1: A brand extends a loan term to 12 months */}
        {/*       </span> */}
        {/*     } */}
        {/*     defaultShow={false} */}
        {/*     className={s.page__repayments_option} */}
        {/*   > */}
        {/*     <div className="f-16 f-400" style={{ marginTop: '10px' }}> */}
        {/*       What happens if a brand doesn&apos;t pay off the remaining part of */}
        {/*       the loan principal in the 6th month? */}
        {/*       <br /> */}
        {/*       Then the repayment schedule is automatically extended for an */}
        {/*       additional 6 months. */}
        {/*     </div> */}
        {/*     <img */}
        {/*       src={B_OPTION_1} */}
        {/*       alt="Option 1" */}
        {/*       className={s.page__repayments_image} */}
        {/*       style={{ */}
        {/*         margin: '30px auto 40px', */}
        {/*         display: 'block', */}
        {/*         width: '100%', */}
        {/*         maxWidth: '700px', */}
        {/*       }} */}
        {/*     /> */}

        {/*     <ul className={s.page__repayments_list}> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 6th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           You do not receive repayments. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 12th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           In the 12th month, you get a 100% of the loan principal and a */}
        {/*           20% flat fee. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*     </ul> */}

        {/*     <Dropdown */}
        {/*       label={ */}
        {/*         <span className="f-16 f-500"> */}
        {/*           A 12-month repayment schedule example */}
        {/*         </span> */}
        {/*       } */}
        {/*       defaultShow */}
        {/*       disableToggle */}
        {/*       className={s.page__repayments_dropdown} */}
        {/*     > */}
        {/*       <> */}
        {/*         <div className={s.page__example_summary}> */}
        {/*           <div className={s.page__example_summary_left}> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Loan amount</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>€1’500</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Origination date</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>05.01.2022</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*           </div> */}
        {/*           <div className={s.page__example_summary_right}> */}
        {/*             /!* <DownloadOptions *!/ */}
        {/*             /!*   links={[ *!/ */}
        {/*             /!*     { format: 'pdf', link: '#' }, *!/ */}
        {/*             /!*     { format: 'xlsx', link: '#' }, *!/ */}
        {/*             /!*     { format: 'csv', link: '#' }, *!/ */}
        {/*             /!*   ]} *!/ */}
        {/*             /!* /> *!/ */}
        {/*           </div> */}
        {/*         </div> */}
        {/*         <div className={s.page__example_data}> */}
        {/*           {window.innerWidth < 992 ? ( */}
        {/*             <ExampleList data={EXAMPLE_B_DATA_12} /> */}
        {/*           ) : ( */}
        {/*             <ExampleTable data={EXAMPLE_B_DATA_12} /> */}
        {/*           )} */}
        {/*         </div> */}
        {/*       </> */}
        {/*     </Dropdown> */}
        {/*   </Dropdown> */}

        {/*   <Dropdown */}
        {/*     label={ */}
        {/*       <span className="f-16 f-500"> */}
        {/*         Option 2: A brand extends a loan term to 18 months */}
        {/*       </span> */}
        {/*     } */}
        {/*     defaultShow={false} */}
        {/*     className={s.page__repayments_option} */}
        {/*   > */}
        {/*     <div className="f-16 f-400" style={{ marginTop: '10px' }}> */}
        {/*       What happens if a brand doesn&apos;t pay off the remaining part of */}
        {/*       the loan principal in the 12th month? */}
        {/*       <br /> */}
        {/*       Then the repayment schedule is automatically extended for an */}
        {/*       additional 6 months. */}
        {/*     </div> */}
        {/*     <img */}
        {/*       src={B_OPTION_2} */}
        {/*       alt="Option 2" */}
        {/*       className={s.page__repayments_image} */}
        {/*       style={{ */}
        {/*         margin: '30px auto 40px', */}
        {/*         display: 'block', */}
        {/*         width: '100%', */}
        {/*       }} */}
        {/*     /> */}

        {/*     <ul className={s.page__repayments_list}> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 12th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           You do not receive repayments. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">In the 18th month</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           In the 18th month, you get 100% of the loan principal and a */}
        {/*           36% flat fee. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*     </ul> */}

        {/*     <Dropdown */}
        {/*       label={ */}
        {/*         <span className="f-16 f-500"> */}
        {/*           An 18-month repayment schedule example */}
        {/*         </span> */}
        {/*       } */}
        {/*       defaultShow */}
        {/*       disableToggle */}
        {/*       className={s.page__repayments_dropdown} */}
        {/*     > */}
        {/*       <> */}
        {/*         <div className={s.page__example_summary}> */}
        {/*           <div className={s.page__example_summary_left}> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Loan amount</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>€1’500</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Origination date</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>05.01.2022</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*           </div> */}
        {/*           <div className={s.page__example_summary_right}> */}
        {/*             /!* <DownloadOptions *!/ */}
        {/*             /!*   links={[ *!/ */}
        {/*             /!*     { format: 'pdf', link: '#' }, *!/ */}
        {/*             /!*     { format: 'xlsx', link: '#' }, *!/ */}
        {/*             /!*     { format: 'csv', link: '#' }, *!/ */}
        {/*             /!*   ]} *!/ */}
        {/*             /!* /> *!/ */}
        {/*           </div> */}
        {/*         </div> */}
        {/*         <div className={s.page__example_data}> */}
        {/*           {window.innerWidth < 992 ? ( */}
        {/*             <ExampleList data={EXAMPLE_B_DATA_18} /> */}
        {/*           ) : ( */}
        {/*             <ExampleTable data={EXAMPLE_B_DATA_18} /> */}
        {/*           )} */}
        {/*         </div> */}
        {/*       </> */}
        {/*     </Dropdown> */}
        {/*   </Dropdown> */}

        {/*   <Dropdown */}
        {/*     label={ */}
        {/*       <span className="f-16 f-500"> */}
        {/*         Option 3: A brand extends a loan term to 24 months */}
        {/*       </span> */}
        {/*     } */}
        {/*     defaultShow={false} */}
        {/*     className={s.page__repayments_option} */}
        {/*   > */}
        {/*     <div className="f-16 f-400" style={{ marginTop: '10px' }}> */}
        {/*       What happens if a brand doesn&apos;t pay off the remaining part of */}
        {/*       the loan principal in the 18th month? */}
        {/*       <br /> */}
        {/*       Then the repayment schedule is automatically extended for an */}
        {/*       additional 6 months. */}
        {/*     </div> */}
        {/*     <img */}
        {/*       src={B_OPTION_3} */}
        {/*       alt="Option 3" */}
        {/*       className={s.page__repayments_image} */}
        {/*       style={{ */}
        {/*         margin: '30px auto 40px', */}
        {/*         display: 'block', */}
        {/*         width: '100%', */}
        {/*       }} */}
        {/*     /> */}

        {/*     <ul className={s.page__repayments_list}> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">From 20th to 23th months</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           From 20th to 23th months, you get a corresponding portion of */}
        {/*           the loan principal each month. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*       <li> */}
        {/*         <div className="f-16-24 f-500">From 21th to 24th months</div> */}
        {/*         <div className="f-16-24 f-400"> */}
        {/*           From 21th to 24th months, you get your flat fee repayments. */}
        {/*         </div> */}
        {/*       </li> */}
        {/*     </ul> */}

        {/*     <Dropdown */}
        {/*       label={ */}
        {/*         <span className="f-16 f-500"> */}
        {/*           A 24-month repayment schedule example */}
        {/*         </span> */}
        {/*       } */}
        {/*       defaultShow */}
        {/*       disableToggle */}
        {/*       className={s.page__repayments_dropdown} */}
        {/*     > */}
        {/*       <> */}
        {/*         <div className={s.page__example_summary}> */}
        {/*           <div className={s.page__example_summary_left}> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Loan amount</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>€1’500</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*             <div className="f-16"> */}
        {/*               <div>Origination date</div> */}
        {/*               <div className="f-500"> */}
        {/*                 <b>05.01.2022</b> */}
        {/*               </div> */}
        {/*             </div> */}
        {/*           </div> */}
        {/*           <div className={s.page__example_summary_right}> */}
        {/*             /!* <DownloadOptions *!/ */}
        {/*             /!*   links={[ *!/ */}
        {/*             /!*     { format: 'pdf', link: '#' }, *!/ */}
        {/*             /!*     { format: 'xlsx', link: '#' }, *!/ */}
        {/*             /!*     { format: 'csv', link: '#' }, *!/ */}
        {/*             /!*   ]} *!/ */}
        {/*             /!* /> *!/ */}
        {/*           </div> */}
        {/*         </div> */}
        {/*         <div className={s.page__example_data}> */}
        {/*           {window.innerWidth < 992 ? ( */}
        {/*             <ExampleList data={EXAMPLE_B_DATA_24} /> */}
        {/*           ) : ( */}
        {/*             <ExampleTable data={EXAMPLE_B_DATA_24} /> */}
        {/*           )} */}
        {/*         </div> */}
        {/*       </> */}
        {/*     </Dropdown> */}
        {/*   </Dropdown> */}
        {/* </div> */}
      </section>
    ),
    agreementSection: (
      <section className={s.page__agreement}>
        <h2 className="f-24 f-500">Loan agreements</h2>
        <p className="f-16 f-400">
          When you set up your investing settings, you accept the terms of the
          Subordinated Financing Agreement or the Annex to Subordinated
          Financing Agreement and the Assignment Agreement, and the Guarantee
          Letter. These files for each created loan are available for download
          inside group B loan cards on the{' '}
          <MyLink href={APP_LINKS.loans}>Loans page</MyLink>.
        </p>
        {/* <MyLink */}
        {/*   isExternal */}
        {/*   href={'APP_LINKS.subordinatedFinancialAgreement'} */}
        {/*   className={s.page__agreement_link} */}
        {/* > */}
        {/*   Download Subordinated Financing Agreement (pdf) */}
        {/* </MyLink> */}
        {/* <MyLink */}
        {/*   isExternal */}
        {/*   href={'APP_LINKS.subordinatedGuaranteeLetter'} */}
        {/*   className={s.page__agreement_link} */}
        {/* > */}
        {/*   Download Guarantee Letter (pdf) */}
        {/* </MyLink> */}
        {/* <MyLink */}
        {/*   isExternal */}
        {/*   href={'APP_LINKS.subordinatedGuaranteeLetter'} */}
        {/*   className={s.page__agreement_link} */}
        {/* > */}
        {/*   Download Annex to Subordinated Financing Agreement (pdf) */}
        {/* </MyLink> */}
        {/* <MyLink */}
        {/*   isExternal */}
        {/*   href={'APP_LINKS.subordinatedGuaranteeLetter'} */}
        {/*   className={s.page__agreement_link} */}
        {/* > */}
        {/*   Download Assignment Agreement (pdf) */}
        {/* </MyLink> */}
      </section>
    ),
  }),
};
