import React, { useState } from 'react';
import { IncreasedIncomeWarning } from '../../components/IncreasedIncomeWarning';
import { Header } from './header';
import { Startups } from './startups';
import { LoanDetails } from './details';
import { FundsRecovery } from './recovery';
import { RepaymentSchedule } from './schedule';
import { RepaymentHistory } from './history';
import { FinanceAgreements } from './agreements';
import { LoanSecurity } from './security';
import { Filter } from './filter';
import s from './index.module.scss';

export const LoanInfo = ({ info }) => {
  const [group, setGroup] = useState();
  const { fundraising_id } = info;

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <Header info={info} />
        <Startups fundraisingID={fundraising_id} />
        <LoanDetails fundraisingID={fundraising_id} />
        <IncreasedIncomeWarning currentDate={info.start_date} />
        <FundsRecovery fundraisingID={fundraising_id} />
        <Filter group={group} onClick={group => setGroup(group)} />
        <RepaymentSchedule group={group} fundraisingID={fundraising_id} />
        <RepaymentHistory group={group} fundraisingID={fundraising_id} />
        <FinanceAgreements
          group={group}
          fundraisingID={fundraising_id}
          info={info}
        />
        <LoanSecurity fundraisingID={fundraising_id} />
      </div>
    </div>
  );
};
