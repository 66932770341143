import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import momentTZ from 'moment-timezone';
import { secondsToDhms } from '../../../helpers/utils';
import { RoundButton } from '../../../components/UI/RoundButton';
import NotificationBlock from '../../NotificationBlock';
import withScroll from '../../../hoc/withScroll';
import { MyLink } from '../../../components/MyLink';
import Dropdown from '../../../components/Dropdown';
import {
  LOAN_GROUPS,
  TIMEZONE_ESTONIA,
  USER_STATUSES,
} from '../../../helpers/constants';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import { APP_LINKS } from '../../../helpers/links';
import WarningBlock from '../../../components/UI/WarningBlock';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import { SuspendResidenceWarning } from '../../../components/Warnings/SuspendResidenceWarning';
import { ChooseStartups } from '../../../components/ChooseStartups';
import { MinInvestAmountWarning } from '../../../components/Warnings/MinInvestAmountWarning';
import { InvestingProgressBar } from '../../../components/InvestingProgressBar';
import { BonusBanner } from '../../BonusProgram/banner';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
  isSubmittedVeriff,
} from '../../../helpers/user';
import { LoanTerms } from '../../../components/LoanTerms';
import {
  CALCULATOR_TYPES,
  LoanCalculator,
} from '../../../components/LoanCalculator';
import './index.scss';

const InvPreRound = ({ status }) => {
  const [groupID, setGroupID] = useState(LOAN_GROUPS.conservative.id); //   long_term | single_loan
  const { veriffStatusCode, profileMissingDetails: missingInfo } = useSelector(
    state => state.profileStore
  );
  const profile = useSelector(state => state.profileStore.profile.data);

  const humanizeDate = value => {
    if (value) {
      const now = moment();
      const date = moment(value);
      const timeLeft = secondsToDhms(date.diff(now, 'seconds'));
      return timeLeft && `Investing will be available in ${timeLeft}`;
    }
  };

  const onCalculate = groupID => {
    setGroupID(groupID || LOAN_GROUPS.conservative.id);
    const el = document.getElementById('calculator');
    el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  if (!profile) {
    return null;
  }

  const notifications = [NOTIFICATIONS.next_round_starts(status.start_date)];

  // TODO SD-188 not finished
  // const notifications = [NOTIFICATIONS.round_ended_earlier()];

  return (
    <div className="inv-pre-round">
      <h1 className="f-42 f-500">The round starts soon</h1>

      {profile?.country_code === 'US' &&
        status?.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className="inv-pre-round__warning-header" />
        )}

      {profile?.status === USER_STATUSES.suspended ? (
        <SuspendResidenceWarning className="inv-pre-round__suspend_top" />
      ) : profile.status !== USER_STATUSES.verified ? (
        <WarningBlock withBorder className="inv-pre-round__warning-header">
          {hasRequiredPersonalSectionFields(profile) &&
          hasRequiredProfileSectionFields(profile) &&
          isSubmittedVeriff(veriffStatusCode) ? (
            <div className="f-16 f-400">
              Investing is available only to verified investors. Your profile
              information is being checked, it may take up to two business days.
            </div>
          ) : (
            <div className="f-16 f-400">
              Complete the verification process to start investing.{' '}
              <MyLink href={APP_LINKS.dashboard} className="c-grey">
                Complete verification&nbsp;→
              </MyLink>
            </div>
          )}
        </WarningBlock>
      ) : (
        <>
          {missingInfo &&
            !missingInfo.has_contact_details &&
            !missingInfo.is_verified &&
            missingInfo.status !== USER_STATUSES.verified && (
              <p className="f-20">
                Prepare in advance by completing the necessary steps:
              </p>
            )}

          <NotificationBlock
            className="inv-pre-round__notifications"
            notifications={notifications}
          />

          <BonusBanner className="inv-pre-round__bonus" />
        </>
      )}

      <InvestingProgressBar className="inv-pre-round__progress" />

      <Dropdown
        title="Consumer Goods Brands batch"
        className="inv-pre-round__founders"
        defaultShow
        disableToggle
      >
        <ParticipatingStartupsList />
      </Dropdown>

      <LoanTerms onCalculate={onCalculate} />

      {/* <LoanCalculator */}
      {/*   groupID={groupID} */}
      {/*   setGroupID={setGroupID} */}
      {/*   status={status} */}
      {/*   onChange={type => */}
      {/*     type === CALCULATOR_TYPES.long_term && setGroupID(null) */}
      {/*   } */}
      {/* /> */}

      <ChooseStartups />

      <div className="inv-pre-round__bottom">
        {profile?.status === USER_STATUSES.suspended ? (
          <SuspendResidenceWarning className="inv-pre-round__suspend_bottom" />
        ) : (
          profile.status !== USER_STATUSES.verified && (
            <WarningBlock className="inv-pre-round__warning-note">
              {hasRequiredPersonalSectionFields(profile) &&
              hasRequiredProfileSectionFields(profile) &&
              isSubmittedVeriff(veriffStatusCode) ? (
                <div className="f-16 f-400">
                  Investing is available only to verified investors. Your
                  profile information is being checked, it may take up to two
                  business days.
                </div>
              ) : (
                <div className="f-16 f-400">
                  Complete the verification process to start investing.{' '}
                  <MyLink href={APP_LINKS.dashboard} className="c-grey">
                    Complete verification&nbsp;→
                  </MyLink>
                </div>
              )}
            </WarningBlock>
          )
        )}
        <RoundButton
          type="button"
          label={
            humanizeDate(
              momentTZ.tz(status.start_date, TIMEZONE_ESTONIA).add(12, 'hours')
            ) || 'Wait a little, investing will start soon'
          }
          className="inv-pre-round__button"
          fillBackground
          fullWidth
          disabled
        />
      </div>
    </div>
  );
};

export default withScroll(InvPreRound);
