import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { RoundButton } from '../../UI/RoundButton';
import {
  DATE_FORMAT_DD_MMM_YYYY,
  DATE_FORMAT_MMM_YYYY,
} from '../../../helpers/common';
import { prettyDate, prettyFloatMoney } from '../../../helpers/utils';
import s from './index.module.scss';

export const LoanCard = ({ round, onDetailsClick, className }) => {
  const {
    fundraising_name,
    start_date,
    end_date,
    flat_fee_accrued,
    loan_amount,
    repayment_status,
    repaid_amount,
    earnings,
  } = round;

  return (
    <div className={classnames(s.card, className)}>
      <div className={s.company}>
        <div className={classnames(s.company_name, 'f-18 f-500')}>
          {fundraising_name}
        </div>
      </div>

      <div className={s.info}>
        <div className={s.row}>
          <div className="c-grey f-14 f-400">Origination date</div>
          <div className="f-18 f-500">
            {moment(start_date).format(DATE_FORMAT_DD_MMM_YYYY)}
          </div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Loan amount</div>
          <div className="f-18 f-500">{prettyFloatMoney(loan_amount)}</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Repayment progress</div>
          <div className="f-18 f-500">{parseInt(repayment_status)}%</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Repayments</div>
          <div className="f-18 f-500">{prettyFloatMoney(repaid_amount)}</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Your earnings</div>
          <div
            className={classnames(
              s.earnings_block
              // flat_fee_accrued && s.earnings_accrued
            )}
          >
            <span className={classnames(s.earnings, 'f-18 f-500')}>
              {`+ ${prettyFloatMoney(earnings)}`}
            </span>
            {/* {!earnings && !!flat_fee_accrued && ( */}
            {/*   <span className="f-12"> */}
            {/*     to be paid in {prettyDate(end_date, DATE_FORMAT_MMM_YYYY)} */}
            {/*   </span> */}
            {/* )} */}
          </div>
        </div>
      </div>

      <RoundButton
        label="More details"
        onClick={onDetailsClick}
        className={s.more}
        fullWidth
      />
    </div>
  );
};
