import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getSellingLoans } from '../../store/actions/loanActions';
import { prettyFloatMoney } from '../../helpers/utils';
import { Table } from './table';
import s from './index.module.scss';

export const SellingLoans = ({ companyID }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.loanStore.sellingLoans);
  const [pageSettings, setPageSettings] = useState({
    page: 1,
    limit: 20,
  });

  useEffect(() => {
    dispatch(getSellingLoans(companyID, pageSettings));
  }, []);

  const getNext = () => {
    const { page } = pageSettings;
    const nextPage = page + 1;

    dispatch(
      getSellingLoans(
        companyID,
        {
          ...pageSettings,
          page: nextPage,
        },
        true
      )
    );

    setPageSettings(prevState => ({ ...prevState, page: nextPage }));
  };

  const hasLoans = data?.total_count > 0;

  return hasLoans ? (
    <div className={s.wrapper}>
      <h2 className="f-22 f-500">Selling loans</h2>
      {data?.total_amount && (
        <div className={classnames(s.total_amount, 'f-16-24')}>
          Total amount: <b>{prettyFloatMoney(data.total_amount)}</b>
        </div>
      )}
      <Table data={data} getNext={getNext} pageSettings={pageSettings} />
    </div>
  ) : null;
};
