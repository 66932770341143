import React, { useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { prettyFloatMoney } from '../../../helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../helpers/common';
import { REPAYMENT_STATUSES } from '../index';
import { InfoIcon } from '../../../components/UI/Icons';
import Tooltip from '../../../components/Tooltip';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import './index.scss';

const RepScheduleTable = ({ data }) => {
  const [activeRows, setActiveRows] = useState([]);

  const onToggle = idx => {
    activeRows.includes(idx)
      ? setActiveRows(activeRows.filter(i => i !== idx))
      : setActiveRows([...activeRows, idx]);
  };

  return (
    <div className="rep_schedule_table__wrap">
      <div className="rep_schedule_table__inner">
        <div className="rep_schedule_table">
          <div className="rep_schedule_table__thead">
            <div className="rep_schedule_table__thead_col">Due date</div>
            <div
              className="rep_schedule_table__thead_col"
              style={{ textAlign: 'center' }}
            >
              Amount
            </div>
            <div className="rep_schedule_table__thead_col">
              Payment Received
            </div>
            <div className="rep_schedule_table__thead_col">Payment Date</div>
            <div className="rep_schedule_table__thead_col">Status</div>
          </div>

          <div className="rep_schedule_table__tbody">
            {data.map((item, idx) => {
              const paidPercentage =
                (item.payment_received / item.total_amount) * 100;

              return (
                <div className="rep_schedule_table__tbody_row" key={idx}>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      REPAYMENT_STATUSES[item.status].className
                    )}
                  >
                    {moment(item.deadline).format(DATE_FORMAT_DD_MMM_YYYY)}
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      REPAYMENT_STATUSES[item.status].className
                    )}
                    onClick={() => onToggle(idx)}
                  >
                    <div className="scr_tree_view">
                      <div>{prettyFloatMoney(item.total_amount)}</div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            marginBottom: '3px',
                            transform: `rotate(${
                              activeRows.includes(idx) ? '180deg' : '0'
                            })`,
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.89072 3.84L6.83832 0.946667C7.05389 0.72 7.05389 0.373333 6.83832 0.16C6.62275 -0.0533333 6.25898 -0.0533333 6.04341 0.16L3.5 2.66667L0.956587 0.16C0.727545 -0.0533333 0.377246 -0.0533333 0.161677 0.16C-0.0538922 0.373333 -0.0538922 0.72 0.161677 0.946667L3.09581 3.84C3.32485 4.05333 3.67515 4.05333 3.89072 3.84Z"
                            fill="black"
                          />
                        </svg>
                        {item.is_extendable && item.status !== 'paid' && (
                          <Tooltip
                            clickMode
                            icon={InfoIcon}
                            position="top center"
                            renderContent={() => (
                              <div className="rep_schedule_table__tooltip f-16  f-400">
                                This scheduled payment can be changed if a
                                startup decides to extend the repayment period
                                for an additional 6 months. In this case, you
                                will receive an additional flat fee for the
                                extension. Learn more in the{' '}
                                <MyLink
                                  href={APP_LINKS.helpLoanTerms()}
                                  underline
                                >
                                  Loan terms
                                </MyLink>
                                .
                              </div>
                            )}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={classnames(
                        'rep_schedule_table__menu f-14 f-400',
                        activeRows.includes(idx) && 'active'
                      )}
                    >
                      {item.interest > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.interest)}</div>
                          <div>Interest</div>
                        </div>
                      )}

                      {item.principal > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.principal)}</div>
                          <div>Principal</div>
                        </div>
                      )}

                      {item.success_fee > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.success_fee)}</div>
                          <div>Success return</div>
                        </div>
                      )}
                      {item.flat_fee > 0 && (
                        <div className="scr_tree_view">
                          <div>{prettyFloatMoney(item.flat_fee)}</div>
                          <div>Flat fee</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      'tl',
                      REPAYMENT_STATUSES[item.status].className
                    )}
                    style={{ color: REPAYMENT_STATUSES[item.status].color }}
                  >
                    {item.payment_received
                      ? prettyFloatMoney(item.payment_received)
                      : '—'}

                    {paidPercentage > 0 && paidPercentage < 100 && (
                      <span>&nbsp;({parseInt(paidPercentage)}%)</span>
                    )}
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      REPAYMENT_STATUSES[item.status].className
                    )}
                  >
                    {item.payment_date
                      ? moment(item.payment_date).format(
                          DATE_FORMAT_DD_MMM_YYYY
                        )
                      : '—'}
                  </div>
                  <div
                    className={classnames(
                      'rep_schedule_table__tbody_col',
                      'tl',
                      REPAYMENT_STATUSES[item.status].className
                    )}
                    style={{ color: REPAYMENT_STATUSES[item.status].color }}
                  >
                    {REPAYMENT_STATUSES[item.status].label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepScheduleTable;
