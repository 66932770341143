import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { MyLink } from '../../../../components/MyLink';
import { APP_LINKS } from '../../../../helpers/links';
import { getAllocatedRepaymentScheduleData } from '../../../../store/services/investingServices';
import { useGetAllocationStatus } from '../../../../hooks/useGetAllocationStatus';
import { LOAN_GROUPS } from '../../../../helpers/constants';
import { IncreasedIncomeWarning } from './income';
import { Table } from './table';
import s from './index.module.scss';

export const RepaymentSchedule = () => {
  const mounted = useRef(true);
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();
  const activeFundraisingId =
    fundraisingDates?.is_active && fundraisingDates?.id;
  const [data, setData] = useState(null);

  const getData = async id => {
    const res = await getAllocatedRepaymentScheduleData(id);
    res?.success && setData(res.data);
  };

  useEffect(() => {
    activeFundraisingId && getData(activeFundraisingId);
    return () => {
      mounted.current = false;
    };
  }, [activeFundraisingId]);

  const getGroup = () => {
    if (allocatedGroupA && allocatedGroupB) {
      return `${LOAN_GROUPS.conservative.full_name} and ${LOAN_GROUPS.moderate.full_name}`;
    }
    if (allocatedGroupA) {
      return LOAN_GROUPS.conservative.full_name;
    }
    if (allocatedGroupB) {
      return LOAN_GROUPS.moderate.full_name;
    }
  };

  const groups = getGroup();

  if (!data) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <h2 className="f-26 f-500">Repayment schedule</h2>
      <div className={s.schedule} style={{ marginTop: '20px' }}>
        {!!allocatedGroupA && (
          <div className="f-16 f-400" style={{ marginBottom: '10px' }}>
            The repayment schedule may be changed if the fundraising goal is
            increased during the round based on actual investor demand. Check
            the final schedule after the end of the round.
          </div>
        )}
        <div className={s.schedule_title}>{groups} loan repayment schedule</div>
        <Table data={data} />
      </div>

      {!!allocatedGroupA && (
        <IncreasedIncomeWarning
          amount={data.increased_income}
          className={s.warning}
        />
      )}

      <div className={classnames(s.hint, 'f-16 f-400')}>
        The repayment schedule is subject to changes in case a startup extends
        loan terms (to a maximum of 24 months) or moves a monthly repayment to
        the last month of the current loan term. Please find more details in the{' '}
        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
          Loan terms
        </MyLink>
        .
      </div>
    </div>
  );
};
