import * as React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import FacebookLoginButton from 'react-facebook-login/dist/facebook-login-render-props';
import config from '../../config';
import Analytics from '../../analyticsGA';
import './index.scss';

import { ReactComponent as IconGoogle } from '../../assets/icons/icon_google_48.svg';
import { ReactComponent as IconApple } from '../../assets/icons/icon_apple.svg';

export const GoogleButton = ({
  label,
  isSignUp,
  onGoogleResponseSuccess,
  onGoogleResponseFailure,
}) => (
  <GoogleLogin
    clientId={config.googleAppId}
    onSuccess={onGoogleResponseSuccess}
    onFailure={onGoogleResponseFailure}
    cookiePolicy="single_host_origin"
    accessType="online"
    buttonText={label}
    className="google_button"
    render={renderProps => (
      <button
        type="button"
        className="social-networks__button google_btn hov"
        onClick={() => {
          renderProps.onClick();
          isSignUp
            ? Analytics.signUpWithGoogle()
            : Analytics.signInWithGoogle();
        }}
      >
        <IconGoogle />
        <span className="f-16-24 f-500">{label}</span>
      </button>
    )}
  />
);

export const AppleButton = ({ label, isSignUp, onResponse, redirectURL }) => (
  <AppleLogin
    clientId={config.appleAppId}
    redirectURI={redirectURL}
    usePopup
    callback={onResponse}
    scope="email name"
    responseMode="query"
    render={renderProps => (
      <button
        type="button"
        className="social-networks__button apple_btn hov"
        onClick={() => {
          renderProps.onClick();
          isSignUp ? Analytics.signUpWithApple() : Analytics.signInWithApple();
        }}
      >
        <IconApple />
        <span className="f-16-24 f-500">{label}</span>
      </button>
    )}
  />
);

export const FacebookButton = ({ onFacebookResponse }) => (
  <FacebookLoginButton
    appId={config.facebookAppId}
    fields="name,email"
    callback={onFacebookResponse}
    render={renderProps => (
      <div
        className="social-networks__button facebook-button"
        onClick={renderProps.onClick}
      >
        <svg
          className="facebook-button__icon"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48 24C48 10.7438 37.2562 0 24 0C10.7438 0 0 10.7438 0 24C0 35.9813 8.775 45.9094 20.25 47.7094V30.9375H14.1562V24H20.25V18.7125C20.25 12.6984 23.8313 9.375 29.3156 9.375C31.9406 9.375 34.6875 9.84375 34.6875 9.84375V15.75H31.6594C28.6781 15.75 27.75 17.6016 27.75 19.5V24H34.4062L33.3422 30.9375H27.75V47.7094C39.225 45.9094 48 35.9813 48 24Z"
            fill="#1877F2"
          />
          <path
            d="M33.3422 30.9375L34.4062 24H27.75V19.5C27.75 17.6016 28.6781 15.75 31.6594 15.75H34.6875V9.84375C34.6875 9.84375 31.9406 9.375 29.3156 9.375C23.8313 9.375 20.25 12.6984 20.25 18.7125V24H14.1562V30.9375H20.25V47.7094C21.4734 47.9016 22.725 48 24 48C25.275 48 26.5266 47.9016 27.75 47.7094V30.9375H33.3422Z"
            fill="white"
          />
        </svg>
      </div>
    )}
  />
);

export const LinkedInButton = ({
  onLinkedInResponseSuccess,
  onLinkedInResponseFailure,
  redirectUri,
}) => (
  <LinkedIn
    clientId={config.linkedinId}
    onFailure={onLinkedInResponseSuccess}
    onSuccess={onLinkedInResponseFailure}
    state="DCEeFWf45A53sdfKef4247s"
    redirectUri={redirectUri}
    scope="r_liteprofile r_emailaddress"
    supportIE
    renderElement={({ onClick }) => (
      <div
        onClick={onClick}
        className="social-networks__button linkedin-button"
      >
        <svg
          className="linkedin-button__icon"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
            fill="#0077B5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3187 14.8227C17.3187 16.3918 16.1376 17.6473 14.2411 17.6473H14.2064C12.3804 17.6473 11.2 16.3918 11.2 14.8227C11.2 13.2204 12.4164 12 14.277 12C16.1376 12 17.2834 13.2204 17.3187 14.8227ZM16.9605 19.8778V36.2196H11.5216V19.8778H16.9605ZM36.5751 36.2196L36.5753 26.8497C36.5753 21.8303 33.8922 19.4941 30.3131 19.4941C27.4254 19.4941 26.1325 21.0802 25.4106 22.1929V19.8783H19.9711C20.0427 21.4117 19.9711 36.22 19.9711 36.22H25.4106V27.0934C25.4106 26.605 25.4459 26.1178 25.5897 25.7681C25.9828 24.7924 26.8778 23.7822 28.3805 23.7822C30.3494 23.7822 31.1364 25.2807 31.1364 27.4767V36.2196H36.5751Z"
            fill="white"
          />
        </svg>
      </div>
    )}
  />
);

FacebookButton.propTypes = { onFacebookResponse: PropTypes.func.isRequired };
GoogleButton.propTypes = {
  onGoogleResponseSuccess: PropTypes.func.isRequired,
  onGoogleResponseFailure: PropTypes.func.isRequired,
};
LinkedInButton.propTypes = {
  onLinkedInResponseSuccess: PropTypes.func.isRequired,
  onLinkedInResponseFailure: PropTypes.func.isRequired,
  redirectUri: PropTypes.string.isRequired,
};
