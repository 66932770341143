import { APP_LINKS } from '../../helpers/links';
import AffiliateIcon from './assets/affiliate_icon.svg';

export const NAV_ROUTES = ({ isActiveReferralBanner }) => [
  { label: 'Dashboard', path: APP_LINKS.dashboard },
  { label: 'Investing', path: APP_LINKS.investing },
  { label: 'Cash', path: APP_LINKS.cash },
  { label: 'Loans', path: APP_LINKS.loans },
  { label: 'Brands gallery', path: APP_LINKS.brandsRoot },
  // { label: 'Investors', path: APP_LINKS.investors },
  { label: 'Reports', path: APP_LINKS.activity },
  {
    label: 'Invite friends',
    path: APP_LINKS.affiliate,
    icon: isActiveReferralBanner && AffiliateIcon,
  },
  { label: 'Rounds history', path: APP_LINKS.roundHistory },
  { label: "Investor's questions", path: APP_LINKS.clientQuestions },
];
