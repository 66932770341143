import React from 'react';
import classnames from 'classnames';
import { prettyDate, prettyFloatMoney } from '../../../../../helpers/utils';
import s from './index.module.scss';

export const Table = props => {
  const { repayment_schedule, total, total_earnings, total_principals } =
    props.data;

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div className={s.table}>
          <div className={s.table__thead}>
            <div className={s.table__thead_col}>Month</div>
            <div className={s.table__thead_col}>Your earnings</div>
            <div className={s.table__thead_col}>Principal</div>
            <div className={classnames(s.table__thead_col, s.table__hide)}>
              Total
            </div>
          </div>

          <div className={s.table__tbody}>
            {repayment_schedule.map((loan, index) => (
              <div key={index} className={classnames(s.table__tbody_row)}>
                <div className={s.table__tbody_col}>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {prettyDate(loan.date, 'DD.MM.YYYY')}
                  </span>
                </div>
                <div className={s.table__tbody_col}>
                  {loan.earnings
                    ? prettyFloatMoney(loan.earnings, { roundUpper: true })
                    : '–'}
                </div>
                <div className={s.table__tbody_col}>
                  {loan.principal
                    ? prettyFloatMoney(loan.principal, { roundUpper: true })
                    : '–'}
                </div>
                <div className={classnames(s.table__tbody_col, s.table__hide)}>
                  {loan.total
                    ? prettyFloatMoney(loan.total, { roundUpper: true })
                    : '–'}
                </div>
              </div>
            ))}

            <div
              className={classnames(
                s.table__tbody_row,
                s.table__tbody_row_result,
                'f-500'
              )}
            >
              <div className={s.table__tbody_col}>Total:</div>
              <div className={s.table__tbody_col}>
                {total_earnings
                  ? prettyFloatMoney(total_earnings, { roundUpper: true })
                  : '–'}
              </div>
              <div className={s.table__tbody_col}>
                {total_principals
                  ? prettyFloatMoney(total_principals, {
                      roundUpper: true,
                    })
                  : '–'}
              </div>
              <div className={classnames(s.table__tbody_col, s.table__hide)}>
                {total ? prettyFloatMoney(total, { roundUpper: true }) : '–'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
