import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import {
  checkActiveFundraising,
  getMessage,
  getQuery,
} from '../../helpers/utils';
import Notify from '../../components/Notification';
import {
  GET_INCREASED_INCOME_STATUS,
  GET_INVESTING_LOAN_GROUPS,
  GET_INVESTING_RESULTS,
  GET_INVESTING_STATUS,
  GET_PARTICIPATING_STARTUPS,
  SET_FUNDRAISING_COUNTRIES_STATS,
  SET_FUNDRAISING_STATS,
  SET_INVESTING_STATUS,
} from '../types/investTypes';

export const getInvestingStatusRequest = () => ({
  type: GET_INVESTING_STATUS.REQUEST,
});
export const getInvestingStatusSuccess = payload => ({
  type: GET_INVESTING_STATUS.SUCCESS,
  payload,
});
export const getInvestingStatusFailure = error => ({
  type: GET_INVESTING_STATUS.FAILURE,
  error,
});

export const getInvestingResultsRequest = () => ({
  type: GET_INVESTING_RESULTS.REQUEST,
});
export const getInvestingResultsSuccess = payload => ({
  type: GET_INVESTING_RESULTS.SUCCESS,
  payload,
});
export const getInvestingResultsFailure = error => ({
  type: GET_INVESTING_RESULTS.FAILURE,
  error,
});

export const getInvestingLoanGroupsRequest = () => ({
  type: GET_INVESTING_LOAN_GROUPS.REQUEST,
});
export const getInvestingLoanGroupsSuccess = payload => ({
  type: GET_INVESTING_LOAN_GROUPS.SUCCESS,
  payload,
});
export const getInvestingLoanGroupsFailure = error => ({
  type: GET_INVESTING_LOAN_GROUPS.FAILURE,
  error,
});

export const getParticipatingStartupsRequest = () => ({
  type: GET_PARTICIPATING_STARTUPS.REQUEST,
});
export const getParticipatingStartupsSuccess = payload => ({
  type: GET_PARTICIPATING_STARTUPS.SUCCESS,
  payload,
});
export const getParticipatingStartupsFailure = error => ({
  type: GET_PARTICIPATING_STARTUPS.FAILURE,
  error,
});

export const getIncreasedIncomeStatusRequest = () => ({
  type: GET_INCREASED_INCOME_STATUS.REQUEST,
});
export const getIncreasedIncomeStatusSuccess = payload => ({
  type: GET_INCREASED_INCOME_STATUS.SUCCESS,
  payload,
});
export const getIncreasedIncomeStatusFailure = error => ({
  type: GET_INCREASED_INCOME_STATUS.FAILURE,
  error,
});

export const setInvestingStatus = status => {
  return dispatch => dispatch({ type: SET_INVESTING_STATUS, status });
};

export const getInvestingStatus = () => {
  return dispatch => {
    dispatch(getInvestingStatusRequest());
    return axios
      .get(Pathes.Investing.status)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getInvestingStatusSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getInvestingStatusFailure(e.message)));
  };
};

export const updateInvestingStatus = payload => {
  return (dispatch, getState) => {
    const copy = {};
    const prevData = getState().investStore.investingStatus.data;
    if (prevData) {
      copy.active_fundraising =
        payload.step === 4 ||
        checkActiveFundraising(payload.start_date, payload.end_date);
    }
    dispatch(
      getInvestingStatusSuccess({
        ...payload,
        ...copy,
      })
    );
  };
};

export const getInvestingLoanGroups = () => {
  return dispatch => {
    dispatch(getInvestingLoanGroupsRequest());
    return axios
      .get(Pathes.Investing.loanGroups)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getInvestingLoanGroupsSuccess(data));
          return data;
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getInvestingLoanGroupsFailure(e.message)));
  };
};

export const getFounderPhotosMaxDiversification = params => {
  return axios
    .get(Pathes.Investing.photosMaxDiversification + getQuery(params))
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const getFounderPhotosParticular = params => {
  return axios
    .get(Pathes.Investing.photosParticular + getQuery(params))
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const getParticularGroups = params => {
  return axios
    .get(Pathes.Investing.investmentGroups + getQuery(params))
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const getCustomFounders = params => {
  return axios
    .get(
      Pathes.Investing.participatingFounders +
        getQuery(params, ['loading', 'founders'])
    )
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const setInvestmentStrategy = payload => {
  return axios
    .post(Pathes.Investing.strategy, payload)
    .then(response => {
      const { status, data } = response;
      const message = getMessage(data);
      if (status === 200) {
        Notify.success({ text: 'Strategy successfully set' });
        return { data, success: true };
      }

      if (status === 406) {
        Notify.error({ text: message });
      }

      throw new Error(message);
    })
    .catch(() => ({ data: null, success: false }));
};

export const getLatestHappyFounders = params => {
  return axios
    .get(
      Pathes.Investing.happyFounders +
        getQuery(params, ['happyFounders', 'loanGroups', 'show'])
    )
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const getInvestingResults = () => {
  return dispatch => {
    dispatch(getInvestingResultsRequest());
    return axios
      .get(Pathes.Investing.results)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getInvestingResultsSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getInvestingResultsFailure(e.message)));
  };
};

export const getInvestmentGroups = () => {
  return axios
    .get(Pathes.Investing.loanGroups)
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const getSelectedParticularGroups = () => {
  return axios
    .get(Pathes.Investing.selectedGroups)
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const getSelectedCustomFounders = params => {
  return axios
    .get(Pathes.Investing.selectedFounders + getQuery(params))
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        return { data, success: true };
      }

      throw new Error('Something went wrong');
    })
    .catch(() => ({ data: null, success: false }));
};

export const setFundraisingStats = payload => ({
  type: SET_FUNDRAISING_STATS,
  payload,
});

// Get fundraising stats
export const getFundraisingStats = () => {
  return dispatch => {
    return axios
      .get(Pathes.Investing.fundraisingStats)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setFundraisingStats(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(() => ({ status: false }));
  };
};

export const setFundraisingCountriesStats = payload => ({
  type: SET_FUNDRAISING_COUNTRIES_STATS,
  payload,
});

// Get fundraising stats
export const getFundraisingCountriesStats = () => {
  return dispatch => {
    return axios
      .get(Pathes.Investing.fundraisingCountriesStats)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setFundraisingCountriesStats(data));
          return { data, success: true };
        }
        throw new Error(message);
      })
      .catch(() => {
        dispatch(setFundraisingCountriesStats(null));
        return { status: false };
      });
  };
};

// Reset investing
export const resetInvesting = () => {
  return dispatch => {
    return axios
      .post(Pathes.Investing.doResetInvestment)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(setInvestingStatus(data));
          Notify.success({ text: 'Investing reset success' });
          return { data, success: true };
        }
        Notify.error({ text: message || 'Investing reset failed' });
        throw new Error(message);
      })
      .catch(e => ({ error: e.message, success: false }));
  };
};

export const getParticipatingStartups = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getParticipatingStartupsRequest());
    return axios
      .get(Pathes.Company.participating + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().investStore.participatingStartups.data;
          if (!isNext || !prevData) {
            dispatch(getParticipatingStartupsSuccess(data));
            return { data, success: true, message };
          }
          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };
          dispatch(getParticipatingStartupsSuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getParticipatingStartupsFailure(e.message)));
  };
};

export const getIncreasedIncomeStatus = () => {
  return dispatch => {
    dispatch(getIncreasedIncomeStatusRequest());
    return axios
      .get(Pathes.Investing.increasedIncome)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getIncreasedIncomeStatusSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getIncreasedIncomeStatusFailure(e.message)));
  };
};
