import React, { useEffect, useRef } from 'react';
import * as classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { createPortal } from 'react-dom';
import { setModals } from '../../store/actions/commonActions';
import { CloseIcon, CloseMobileIcon } from '../UI/Icons';
import StandardConfirm from '../Dialogs/StandardConfirm';
import CompanyInfoModal from '../../modals/CompanyInfoModal';
import { bodyScrollLock } from '../../helpers/utils';
import AllocateFundModal from '../../modals/AllocateFundModal';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import ProfileSummaryModal from '../../modals/ProfileSummaryModal';
import ProfilePersonalModal from '../../modals/ProfilePersonalModal';
import ProfileAvatarModal from '../../modals/ProfileAvatarModal';
import LoanInfoModal from '../../modals/LoanInfoModal';
import WizardProfileDetailModal from '../../modals/WizardProfileDetailModal';
import WizardPersonalInfoModal from '../../modals/WizardPersonalInfoModal';
import WizardVeriffModal from '../../modals/WizardVeriffModal';
import ReferralEmailModal from '../../modals/ReferralEmailModal';
import BonusBannerModal from '../../modals/BonusBannerModal';
import InvestorTypeModal from '../../modals/InvestorTypeModal';
import PhoneVerificationModal from '../../modals/PhoneVerificationModal';
import InfoModal from '../../modals/InfoModal';
import ConfirmModal from '../../modals/ConfirmModal';
import ComplaintDetailModal from '../../modals/ComplaintDetailModal';
import ComplaintRemoveModal from '../../modals/ComplaintRemoveModal';
import QuestionnaireModal from '../../modals/QuestionnaireModal';
import TermsOfUseModal from '../../modals/TermsOfUseModal';
import BonusInviteFriendsModal from '../../modals/BonusInviteFriendsModal';
import BonusInviteFriendsInvestingModal from '../../modals/BonusInviteFriendsInvestingModal';
import SepaInfoModal from '../../modals/SepaInfoModal';
import './index.scss';

export const MODAL_TYPES = {
  standard_confirm: 'standard_confirm',
  allocate_fund: 'allocate_fund',
  company_info: 'company_info',
  change_password: 'change_password',
  profile_detail: 'profile_detail',
  profile_personal: 'profile_personal',
  profile_avatar: 'profile_avatar',
  loan_info: 'loan_info',
  wizard_profile_detail: 'wizard_profile_detail',
  wizard_personal_information: 'wizard_personal_information',
  wizard_veriff: 'wizard_veriff',
  referral_email: 'referral_email',
  bonus_banner: 'bonus_banner',
  investor_type: 'investor_type',
  phone_verification: 'phone_verification',
  info_modal: 'info_modal',
  confirm_modal: 'confirm_modal',
  complaint_detail: 'complaint_detail',
  complaint_remove: 'complaint_remove',
  questionnaire: 'questionnaire',
  terms_of_use: 'terms_of_use',
  bonus_invite_friend: 'bonus_invite_friend',
  bonus_invite_friend_investing: 'bonus_invite_friend_investing',
  sepa_info_modal: 'sepa_info_modal',
};

export const ModalProvider = () => {
  const elementID = 'modals';
  const { modals, innerWidth } = useSelector(state => state.commonStore);
  const history = useHistory();
  const dispatch = useDispatch();
  const root = useRef(document.getElementById(elementID));

  const onClose = type => {
    type
      ? dispatch(setModals(modals.filter(view => view.type !== type)))
      : dispatch(setModals([]));
  };

  useEffect(() => {
    if (modals && !!modals.length) {
      bodyScrollLock(true);
      root.current.classList.add('active');
    } else {
      bodyScrollLock(false);
      root.current.classList.remove('active');
    }
  }, [modals]);

  useEffect(() => {
    return () => {
      bodyScrollLock(false);
    };
  }, []);

  useEffect(() => {
    history.listen(() => {
      onClose();
    });
  }, []);

  const renderModal = modal => {
    switch (modal.type) {
      case MODAL_TYPES.standard_confirm:
        return (
          <StandardConfirm onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.allocate_fund:
        return (
          <AllocateFundModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.company_info:
        return (
          <CompanyInfoModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.change_password:
        return (
          <ChangePasswordModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.profile_detail:
        return (
          <ProfileSummaryModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.profile_personal:
        return (
          <ProfilePersonalModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.profile_avatar:
        return (
          <ProfileAvatarModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.loan_info:
        return <LoanInfoModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.wizard_profile_detail:
        return (
          <WizardProfileDetailModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.wizard_personal_information:
        return (
          <WizardPersonalInfoModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.wizard_veriff:
        return (
          <WizardVeriffModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.referral_email:
        return (
          <ReferralEmailModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.bonus_banner:
        return (
          <BonusBannerModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.investor_type:
        return (
          <InvestorTypeModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.phone_verification:
        return (
          <PhoneVerificationModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.info_modal:
        return <InfoModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.confirm_modal:
        return <ConfirmModal onClose={() => onClose(modal.type)} {...modal} />;
      case MODAL_TYPES.complaint_detail:
        return (
          <ComplaintDetailModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.complaint_remove:
        return (
          <ComplaintRemoveModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.questionnaire:
        return (
          <QuestionnaireModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.terms_of_use:
        return (
          <TermsOfUseModal onClose={() => onClose(modal.type)} {...modal} />
        );
      case MODAL_TYPES.bonus_invite_friend:
        return (
          <BonusInviteFriendsModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.bonus_invite_friend_investing:
        return (
          <BonusInviteFriendsInvestingModal
            onClose={() => onClose(modal.type)}
            {...modal}
          />
        );
      case MODAL_TYPES.sepa_info_modal:
        return <SepaInfoModal onClose={() => onClose(modal.type)} {...modal} />;

      default:
        return null;
    }
  };

  const content =
    modals &&
    !!modals.length &&
    modals.map((modal, index) => (
      <div
        key={modal.type}
        id={`modals_wrap_${modal.type}`}
        className={classnames('modals-wrap', modal.type)}
      >
        <div className="modals-wrap__inner" onClick={e => e.stopPropagation()}>
          <OutsideClickHandler
            onOutsideClick={() => onClose(modal.type)}
            disabled={!!modal.onOutsideClick || modals.length - 1 !== index}
          >
            {!modal.disableCloseIcon && (
              <button
                type="button"
                onClick={() => onClose(modal.type)}
                className="modals-wrap__close"
              >
                {innerWidth < 992 ? <CloseMobileIcon /> : <CloseIcon />}
              </button>
            )}
            {renderModal(modal)}
          </OutsideClickHandler>
        </div>
      </div>
    ));

  return root.current && createPortal(content, root.current);
};
