import React from 'react';
import classnames from 'classnames';
import { prettyDate, prettyFloatMoney } from '../../../helpers/utils';
import s from './index.module.scss';

export const Table = ({ data, pageSettings, getNext }) => (
  <div>
    <div className={s.table__wrapper}>
      <div className={s.table__inner}>
        <div className={s.table}>
          <div className={s.table__thead}>
            <div className={s.table__thead_col}>Loan #</div>
            <div className={s.table__thead_col}>Created date</div>
            <div className={s.table__thead_col}>Amount</div>
          </div>

          <div className={s.table__tbody}>
            {data?.list.map(item => (
              <div
                key={item.loan_id}
                className={classnames(s.table__tbody_row, 'hov')}
              >
                <div className={s.table__tbody_col}>#{item.loan_id}</div>
                <div className={s.table__tbody_col}>
                  {prettyDate(item.created_at)}
                </div>
                <div className={s.table__tbody_col}>
                  {prettyFloatMoney(item.amount)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    {data?.total_pages > pageSettings.page && (
      <div style={{ textAlign: 'center' }}>
        <button
          type="button"
          className={classnames(s.more, 'f-16-24')}
          onClick={getNext}
        >
          Load more
        </button>
      </div>
    )}
  </div>
);
