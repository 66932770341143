import axios from '../../axios-api';
import Pathes from '../../helpers/pathes';
import { getMessage, getQuery } from '../../helpers/utils';
import {
  GET_AGREEMENTS_BY_FUNDRAISING_ID,
  GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID,
  GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID,
  GET_LOAN_SECURITY_BY_FUNDRAISING_ID,
  GET_LOAN_TERMS,
  GET_LOANS_BY_FUNDRAISING_ID,
  GET_LOANS_LIST,
  GET_PAYMENT_HISTORY,
  GET_REPAYMENT_SCHEDULE,
  GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID,
  GET_REPAYMENT_SCHEDULE_BY_LOAN_ID,
  GET_SELLING_LOANS,
  GET_STARTUPS_BY_FUNDRAISING_ID,
} from '../types/loanTypes';

export const getLoansListRequest = () => ({ type: GET_LOANS_LIST.REQUEST });
export const getLoansListSuccess = payload => ({
  type: GET_LOANS_LIST.SUCCESS,
  payload,
});
export const getLoansListFailure = error => ({
  type: GET_LOANS_LIST.FAILURE,
  error,
});

export const getLoanTermsRequest = () => ({ type: GET_LOAN_TERMS.REQUEST });
export const getLoanTermsSuccess = payload => ({
  type: GET_LOAN_TERMS.SUCCESS,
  payload,
});
export const getLoanTermsFailure = error => ({
  type: GET_LOAN_TERMS.FAILURE,
  error,
});

export const getPaymentHistoryRequest = () => ({
  type: GET_PAYMENT_HISTORY.REQUEST,
});
export const getPaymentHistorySuccess = payload => ({
  type: GET_PAYMENT_HISTORY.SUCCESS,
  payload,
});
export const getPaymentHistoryFailure = error => ({
  type: GET_PAYMENT_HISTORY.FAILURE,
  error,
});

export const getRepaymentScheduleRequest = () => ({
  type: GET_REPAYMENT_SCHEDULE.REQUEST,
});
export const getRepaymentScheduleSuccess = payload => ({
  type: GET_REPAYMENT_SCHEDULE.SUCCESS,
  payload,
});
export const getRepaymentScheduleFailure = error => ({
  type: GET_REPAYMENT_SCHEDULE.FAILURE,
  error,
});

export const getRepaymentScheduleByLoanIDRequest = () => ({
  type: GET_REPAYMENT_SCHEDULE_BY_LOAN_ID.REQUEST,
});
export const getRepaymentScheduleByLoanIDSuccess = payload => ({
  type: GET_REPAYMENT_SCHEDULE_BY_LOAN_ID.SUCCESS,
  payload,
});
export const getRepaymentScheduleByLoanIDFailure = error => ({
  type: GET_REPAYMENT_SCHEDULE_BY_LOAN_ID.FAILURE,
  error,
});

export const getLoansByFundraisingIDRequest = () => ({
  type: GET_LOANS_BY_FUNDRAISING_ID.REQUEST,
});

export const getLoansByFundraisingIDSuccess = payload => ({
  type: GET_LOANS_BY_FUNDRAISING_ID.SUCCESS,
  payload,
});

export const getLoansByFundraisingIDFailure = error => ({
  type: GET_LOANS_BY_FUNDRAISING_ID.FAILURE,
  error,
});

export const getFundsInRecoveryByFundraisingIDRequest = () => ({
  type: GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID.REQUEST,
});

export const getFundsInRecoveryByFundraisingIDSuccess = payload => ({
  type: GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID.SUCCESS,
  payload,
});

export const getFundsInRecoveryByFundraisingIDFailure = error => ({
  type: GET_FUNDS_IN_RECOVERY_BY_FUNDRAISING_ID.FAILURE,
  error,
});

export const getStartupsByFundraisingIDRequest = () => ({
  type: GET_STARTUPS_BY_FUNDRAISING_ID.REQUEST,
});

export const getStartupsByFundraisingIDSuccess = payload => ({
  type: GET_STARTUPS_BY_FUNDRAISING_ID.SUCCESS,
  payload,
});

export const getStartupsByFundraisingIDFailure = error => ({
  type: GET_STARTUPS_BY_FUNDRAISING_ID.FAILURE,
  error,
});

export const getRepaymentScheduleByFundraisingIDRequest = () => ({
  type: GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID.REQUEST,
});

export const getRepaymentScheduleByFundraisingIDSuccess = payload => ({
  type: GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID.SUCCESS,
  payload,
});

export const getRepaymentScheduleByFundraisingIDFailure = error => ({
  type: GET_REPAYMENT_SCHEDULE_BY_FUNDRAISING_ID.FAILURE,
  error,
});

export const getLoanPaymentHistoryByFundraisingIDRequest = () => ({
  type: GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID.REQUEST,
});

export const getLoanPaymentHistoryByFundraisingIDSuccess = payload => ({
  type: GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID.SUCCESS,
  payload,
});

export const getLoanPaymentHistoryByFundraisingIDFailure = error => ({
  type: GET_LOAN_PAYMENT_HISTORY_BY_FUNDRAISING_ID.FAILURE,
  error,
});

export const getAgreementsByFundraisingIDRequest = () => ({
  type: GET_AGREEMENTS_BY_FUNDRAISING_ID.REQUEST,
});

export const getAgreementsByFundraisingIDSuccess = payload => ({
  type: GET_AGREEMENTS_BY_FUNDRAISING_ID.SUCCESS,
  payload,
});

export const getAgreementsByFundraisingIDFailure = error => ({
  type: GET_AGREEMENTS_BY_FUNDRAISING_ID.FAILURE,
  error,
});

export const getSellingLoansRequest = () => ({
  type: GET_SELLING_LOANS.REQUEST,
});
export const getSellingLoansSuccess = payload => ({
  type: GET_SELLING_LOANS.SUCCESS,
  payload,
});
export const getSellingLoansFailure = error => ({
  type: GET_SELLING_LOANS.FAILURE,
  error,
});

export const getLoanSecurityByFundraisingIdRequest = () => ({
  type: GET_LOAN_SECURITY_BY_FUNDRAISING_ID.REQUEST,
});
export const getLoanSecurityByFundraisingIdSuccess = payload => ({
  type: GET_LOAN_SECURITY_BY_FUNDRAISING_ID.SUCCESS,
  payload,
});
export const getLoanSecurityByFundraisingIdFailure = error => ({
  type: GET_LOAN_SECURITY_BY_FUNDRAISING_ID.FAILURE,
  error,
});

export const getLoansList = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getLoansListRequest());
    return axios
      .get(Pathes.Loans.list + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().loanStore.loansList.data;
          if (!isNext || !prevData) {
            dispatch(getLoansListSuccess(data));
            return { data, success: true, message };
          }

          const prevValues = prevData || {};
          const updatedData = {
            ...prevValues,
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getLoansListSuccess(updatedData));
          return { ...updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getLoansListFailure(e.message)));
  };
};

export const getLoanTerms = () => {
  return dispatch => {
    dispatch(getLoanTermsRequest());
    return axios
      .get(Pathes.Loans.loanTerms)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const groups = data.reduce((acc, item) => {
            acc[item.id] = { ...item };
            return acc;
          }, {});

          dispatch(getLoanTermsSuccess(groups));
          return { data: groups, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getLoanTermsFailure(e.message)));
  };
};

export const getPaymentHistory = (id, params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getPaymentHistoryRequest());
    return axios
      .get(Pathes.Loans.paymentHistory(id) + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().loanStore.paymentHistory.data;
          if (!isNext || !prevData) {
            dispatch(getPaymentHistorySuccess(data));
            return { data, success: true, message };
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getPaymentHistorySuccess(updatedData));
          return { data: updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getPaymentHistoryFailure(e.message)));
  };
};

// Get investor repayment schedule
export const getRepaymentSchedule = params => {
  return dispatch => {
    dispatch(getRepaymentScheduleRequest());
    return axios
      .get(Pathes.Loans.repaymentSchedule + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getRepaymentScheduleSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getRepaymentScheduleFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

// Get investor repayment schedule by Loan ID
export const getRepaymentScheduleByLoanID = loanID => {
  return dispatch => {
    dispatch(getRepaymentScheduleByLoanIDRequest());
    return axios
      .get(Pathes.Loans.repaymentScheduleByLoanID(loanID))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getRepaymentScheduleByLoanIDSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getRepaymentScheduleByLoanIDFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

export const getLoansByFundraisingID = id => {
  return dispatch => {
    dispatch(getLoansByFundraisingIDRequest());
    return axios
      .get(Pathes.Loans.loansByFundraisingID(id))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getLoansByFundraisingIDSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getLoansByFundraisingIDFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

export const getFundsInRecoveryByFundraisingID = id => {
  return dispatch => {
    dispatch(getFundsInRecoveryByFundraisingIDRequest());
    return axios
      .get(Pathes.Loans.fundsInRecoveryByFundraisingID(id))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getFundsInRecoveryByFundraisingIDSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getFundsInRecoveryByFundraisingIDFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

export const getStartupsByFundraisingID = id => {
  return dispatch => {
    dispatch(getStartupsByFundraisingIDRequest());
    return axios
      .get(Pathes.Loans.startupsByFundraisingID(id))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getStartupsByFundraisingIDSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getStartupsByFundraisingIDFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

export const getRepaymentScheduleByFundraisingID = (id, params) => {
  return dispatch => {
    dispatch(getRepaymentScheduleByFundraisingIDRequest());
    return axios
      .get(Pathes.Loans.repaymentScheduleByFundraisingID(id) + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getRepaymentScheduleByFundraisingIDSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getRepaymentScheduleByFundraisingIDFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

export const getLoanPaymentHistoryByFundraisingID = (id, params) => {
  return dispatch => {
    dispatch(getLoanPaymentHistoryByFundraisingIDRequest());
    return axios
      .get(Pathes.Loans.paymentHistoryByFundraisingID(id) + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getLoanPaymentHistoryByFundraisingIDSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getLoanPaymentHistoryByFundraisingIDFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

export const getAgreementsByFundraisingID = (id, params) => {
  return dispatch => {
    dispatch(getAgreementsByFundraisingIDRequest());
    return axios
      .get(Pathes.Loans.agreementsByFundraisingID(id) + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getAgreementsByFundraisingIDSuccess(data));
          return { data, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => {
        dispatch(getAgreementsByFundraisingIDFailure(e.message));
        return { success: false, message: e.message };
      });
  };
};

export const getSellingLoans = (companyId, params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getSellingLoansRequest());
    return axios
      .get(Pathes.Loans.sellingLoans(companyId) + getQuery(params))
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().loanStore.sellingLoans.data;
          if (!isNext || !prevData) {
            dispatch(getSellingLoansSuccess(data));
            return { data, success: true, message };
          }

          const prevValues = prevData || {};
          const updatedData = {
            ...prevValues,
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getSellingLoansSuccess(updatedData));
          return { ...updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getSellingLoansFailure(e.message)));
  };
};

export const getLoanSecurityByFundraisingId = (
  fundraisingId,
  params,
  isNext
) => {
  return (dispatch, getState) => {
    dispatch(getLoanSecurityByFundraisingIdRequest());
    return axios
      .get(
        Pathes.Loans.loanSecurityByFundraisingId(fundraisingId) +
          getQuery(params)
      )
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData =
            getState().loanStore.loanSecurityByFundraisingID.data;
          if (!isNext || !prevData) {
            dispatch(getLoanSecurityByFundraisingIdSuccess(data));
            return { data, success: true, message };
          }

          const prevValues = prevData || {};
          const updatedData = {
            ...prevValues,
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [...prevData.list, ...data.list],
          };

          dispatch(getLoanSecurityByFundraisingIdSuccess(updatedData));
          return { ...updatedData, success: true, message };
        }
        throw new Error(message);
      })
      .catch(e => dispatch(getLoanSecurityByFundraisingIdFailure(e.message)));
  };
};
