import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StartupCard } from '../../../../components/Cards/StartupCard/v3';
import { getParticipatingStartups } from '../../../../store/actions/investActions';
import { RoundButton } from '../../../../components/UI/RoundButton';
import s from './participate.module.scss';

export const ParticipatingStartupsList = ({ onMoreDetails }) => {
  const [params, setParams] = useState({
    page: 1,
    limit: 4,
  });
  const dispatch = useDispatch();
  const { participatingStartups: startups } = useSelector(
    state => state.investStore
  );

  useEffect(() => {
    dispatch(getParticipatingStartups(params));
  }, []);

  const getNext = totalPages => {
    if (params.page < totalPages) {
      const nextPage = params.page + 1;
      dispatch(
        getParticipatingStartups(
          {
            limit: params.limit,
            page: nextPage,
          },
          true
        )
      );

      return setParams(prevState => ({
        ...prevState,
        limit: prevState.limit,
        page: nextPage,
      }));
    }
  };

  return (
    <div className={s.container}>
      {startups.data && startups.data.total_count === 0 && (
        <div className={s.empty}>
          Please wait a while. Participants of the round will appear here soon.
        </div>
      )}

      <div className={s.list}>
        {startups.data?.list.map(startup => (
          <StartupCard
            key={startup.id}
            startup={startup}
            className={s.item}
            onMoreDetails={onMoreDetails}
          />
        ))}
      </div>

      {params.page < startups.data?.total_pages && (
        <div className={s.buttons}>
          <RoundButton
            type="button"
            label="Show more startups"
            onClick={() => getNext(startups.data?.total_pages)}
          />
        </div>
      )}
    </div>
  );
};
