import React from 'react';
import moment from 'moment';
import Preloader from '../../components/Preloader';
import { prettyFloatMoney } from '../../helpers/utils';
import s from './index.module.scss';

const BonusBannerModal = ({ props }) => {
  const {
    bonus_amount,
    investment_amount,
    promotion_for,
    expiration_days,
    fundraising_end_date,
    start_date,
    end_date,
  } = props;

  return props ? (
    <div className={s.modal}>
      <h1 className="f-32 f-500">
        Maximize your earnings with a {prettyFloatMoney(bonus_amount)} bonus for
        every {prettyFloatMoney(investment_amount)} investment in Group A
      </h1>
      <section className="f-16-24">
        <h2 className="f-20 f-500">How does it work?</h2>
        <div>
          Every{' '}
          <span className="f-500">{prettyFloatMoney(investment_amount)}</span>{' '}
          initial invested in Group A, gets you an additional{' '}
          {prettyFloatMoney(bonus_amount)} bonus:
        </div>
        <ul className={s.list}>
          <li>
            an investment of less than{' '}
            <span className="f-500">{prettyFloatMoney(investment_amount)}</span>{' '}
            will not get you a bonus,
          </li>
          <li>
            an investment over{' '}
            <span className="f-500">{prettyFloatMoney(investment_amount)}</span>{' '}
            will get you a total of{' '}
            <span className="f-500">{prettyFloatMoney(bonus_amount)}</span>{' '}
            bonus,
          </li>
          <li>
            an investment over{' '}
            <span className="f-500">
              {prettyFloatMoney(investment_amount * 2)}
            </span>{' '}
            will get you a total of{' '}
            <span className="f-500">{prettyFloatMoney(bonus_amount * 2)}</span>{' '}
            bonus,
          </li>
          <li>
            over{' '}
            <span className="f-500">
              {prettyFloatMoney(investment_amount * 3)}
            </span>{' '}
            - a{' '}
            <span className="f-500">{prettyFloatMoney(bonus_amount * 3)}</span>{' '}
            bonus, and so on and so forth.
          </li>
        </ul>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">Who is the promotion for?</h2>
        <div>{promotion_for}</div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">When will I get my bonuses?</h2>
        <div>
          Bonuses will be accrued to your Scramble cash account after the
          current round is finished, on{' '}
          <span className="nowrap">
            {moment(fundraising_end_date).add(1, 'day').format('MMMM Do, YYYY')}
          </span>
          .
        </div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">Can I withdraw my bonuses?</h2>
        <div>
          You can use the acquired bonuses for your future investments on
          Scramble, however, you cannot cash them out.
        </div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">For how long can I use them?</h2>
        <div>
          You can use your bonuses for up to {expiration_days} days from the
          moment they are added to your Scramble account.
        </div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">
          What happens if I invest my active bonus?
        </h2>
        <div>
          Invested bonuses do not participate in the calculation of the
          promotion. Only your personal funds that have been added to your
          Scramble account or received as payouts and earnings on the platform
          are taken into account.
        </div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">How long will this offer last?</h2>
        <div>
          This promotion start on{' '}
          <span className="nowrap">
            {moment(start_date).format('MMMM Do, YYYY')}
          </span>{' '}
          , and ends on{' '}
          <span className="nowrap">
            {moment(end_date).format('MMMM Do, YYYY')}
          </span>
          .
        </div>
      </section>
    </div>
  ) : (
    <Preloader style={{ height: '50vh', width: '40vw' }} />
  );
};

export default BonusBannerModal;
