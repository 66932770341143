import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import store, { history } from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import App from './App';

import 'normalize.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { ErrorBoundaryFallback } from './components/ErrorBoundaryFallback';
import './assets/styles/typography.scss';
import './index.scss';
import './assets/styles/themes/default.scss';
import { initYup } from './yup-config';

Modal.setAppElement('#root');

initYup();

const app = (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
        <ToastContainer />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
