import config from '../config';
import { CONTACT_EMAIL, CONTACT_PHONE } from './constants';

export const APP_LINKS = {
  root: '/',
  dashboard: '/dashboard',
  investing: '/investing',
  roundHistory: '/rounds-history',
  forgotPassword: '/forgot-password',
  signIn: '/sign-in',
  twoFactorAuth: '/2fa',
  signUp: '/sign-up',
  emailVerificationStatus: (code = ':code') => `/email-verification/${code}`,
  cash: '/funds',
  addFunds: '/funds/add-funds',
  addFundsResult: '/funds/add-funds/result',
  withdraw: '/funds/withdraw',
  bankAccounts: '/funds/bank-accounts',
  bankAccountsCreate: '/funds/bank-accounts/new',
  bankAccountsEdit: id => `/funds/bank-accounts/${id}`,
  allocateCash: '/funds/allocate-cash',
  loans: '/loans',
  loansList: '/loans/list',
  loansSchedule: '/loans/schedule',
  brandsRoot: '/brands',
  startups: '/brands/brands',
  founders: '/brands/founders',
  investors: '/investors',
  reports: '/reports',
  activity: '/reports/activity',
  taxReports: '/reports/tax-report',
  profile: '/profile',
  affiliate: '/affiliate',
  help: '/help',
  clientQuestions: '/client-questions',
  complaints: '/complaints',
  complaintCreate: '/complaint-create',
  helpLoanTerms: version => `/help/loan-terms${version ? `/${version}` : ''}`,
  helpLoanTermsGroup: (group = ':group') => `/help/loan-terms-group/${group}`,
  helpPrivateInvestors: '/help/private-investors',
  support: `mailto:ask@scrambleup.com`,
  home: config.investorPromoDomain,
  promoRound: `${config.mainPromoDomain}/round`,
  promoRoundsHistory: `${config.mainPromoDomain}/rounds-history`,
  blog: `${config.mainPromoDomain}/blog`,
  privateInvestorPDF:
    'https://99.scrambleup.com/static/media/Scramble300.7b818638.pdf',
  privacyPolicy: `${config.mainPromoDomain}/docs/Privacy_policy.pdf`,
  termOfUse: `${config.mainPromoDomain}/docs/Terms_of_use.pdf`,
  unsubordinatedFinancialAgreement:
    config.mainPromoDomain +
    '/docs/scramble_financing_agreement_unsubordinated_loans.pdf',
  subordinatedFinancialAgreement:
    config.mainPromoDomain +
    '/docs/scramble_financing_agreement_subordinated_loans.pdf',
  assignmentAgreements:
    config.mainPromoDomain +
    '/docs/Scramble_Assignment_Agreement_General_Terms.pdf',
  subordinatedGuaranteeLetter:
    config.mainPromoDomain + '/docs/scramble_guarantee_letter_subordinated.pdf',
  foundersPromo: config.founderPromoDomain,
  investorsPromo: config.investorPromoDomain,
  mainPromo: config.mainPromoDomain,
  promoProduct: config.investorPromoDomain + '/product',
  promoHowItWorks: config.investorPromoDomain + '/how-it-works',
  promoAbout: config.investorPromoDomain + '/about',
  promoContacts: config.investorPromoDomain + '/contacts',
  promoHelp: config.investorPromoDomain + '/help',
  docTermsOfUse: config.mainPromoDomain + '/docs/Terms_of_use.pdf',
  docTermsAndConditions:
    config.mainPromoDomain + '/docs/Affiliate_Program_Scramble_2024.pdf',
  docPrivacyAndPolicy: config.mainPromoDomain + '/docs/Privacy_policy.pdf',
  docBonusProgram: config.mainPromoDomain + '/docs/scramble_5_euro_program.pdf',
  docPercentTwoDecember:
    config.mainPromoDomain + '/docs/scramble_december_bonus.pdf',
  docAnnexToSubordinatedLoansAgreement:
    config.mainPromoDomain +
    '/docs/scramble_annex_to_subordinated_loans_agreement.pdf',
  docAnnexToUnSubordinatedLoansAgreement:
    config.mainPromoDomain +
    '/docs/scramble_annex_to_unsubordinated_loans_agreement.pdf',
  docAssignmentAgreementGeneralTerms:
    config.mainPromoDomain +
    '/docs/Scramble_Assignment_Agreement_General_Terms.pdf',
  docAffiliateProgramScramble2024:
    config.mainPromoDomain + '/docs/Affiliate_Program_Scramble_2024.pdf',
  docAffiliateProgramAdditional5Scramble2024:
    config.mainPromoDomain +
    '/docs/Affiliate_Program_Additional_5_Scramble_2024.pdf',
  docScrambleFiveEuroProgram:
    config.mainPromoDomain + '/docs/Scramble_5_Euro_Program_GroupA.pdf',
  trustpilot: 'https://www.trustpilot.com/evaluate/scrambleup.com',
  phone: `tel:${CONTACT_PHONE.replace(' ', '')}`,
  email: `mailto:${CONTACT_EMAIL}`,
  startupDetail: slug => `/brands/brands/${slug}`,
  founderDetail: id => `/brands/founders/${id}`,
};
