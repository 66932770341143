import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BonusBanner } from '../../BonusProgram/banner';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import Avatar from '../../../components/UI/Avatar';
import {
  getNameInitials,
  isParticipatingInvesting,
} from '../../../helpers/utils';
import { MinInvestAmountWarning } from '../../../components/Warnings/MinInvestAmountWarning';
import NotificationBlock from '../../NotificationBlock';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import { isVerifiedEmail, isVerifiedPhoneNumber } from '../../../helpers/user';
import { RoundLink } from '../../../components/UI/RoundLink';
import { STRATEGIES } from '../../InvestingModule/InvSelectStrategy';
import InvestingInfo from '../../InvestingInfo';
import {
  getEarnings,
  getHappyFounders,
  getLoanGroups,
} from '../../../store/actions/dashboardActions';
import { LoanBasket } from './LoanBasket';
import { BalanceInfo } from './BalanceInfo';
import { IncomeBlock } from './IncomeBlock';
import { HappyFounders } from './HappyFounders';
import AffiliateSteps from '../../../components/AffiliateSteps';
import s from './index.module.scss';

export const InvestedUsersView = ({ isActiveFR }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { balance, earnings, happyFounders, fundraisingDates, loanGroups } =
    useSelector(state => state.dashboardStore);
  const { investingResult } = useSelector(state => state.investStore);
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const { data: status } = useSelector(
    state => state.investStore.investingStatus
  );

  useEffect(() => {
    dispatch(getEarnings());
    dispatch(getHappyFounders());
    dispatch(getLoanGroups());
  }, []);

  const hasLoans = !!investingResult.data?.total_invested;

  const notifications = [];

  !fundraisingDates?.is_active &&
    notifications.unshift(
      NOTIFICATIONS.next_round_starts(fundraisingDates.start_date)
    );

  status &&
    status.is_active &&
    (status.step === 3
      ? notifications.push(NOTIFICATIONS.round_results(status.end_date))
      : notifications.push(NOTIFICATIONS.round_has_started()));

  !isVerifiedPhoneNumber(profile) &&
    notifications.push(NOTIFICATIONS.verify_phone_number());

  !isVerifiedEmail(profile) && notifications.push(NOTIFICATIONS.verify_email());

  return (
    <>
      <BonusBanner className={s.banner} />
      <div className={s.header}>
        <div className={s.header__row}>
          <MyLink href={APP_LINKS.profile} className={s.avatar__link}>
            <Avatar
              src={profile.photo && profile.photo.file}
              size={65}
              alt={getNameInitials(profile.first_name, profile.last_name)}
              nameInitials={getNameInitials(
                profile.first_name,
                profile.last_name
              )}
              className={s.avatar}
              active={isParticipatingInvesting(status)}
            />
          </MyLink>
        </div>

        <div className={classnames('f-42 f-500', s.title)}>
          {profile.first_name
            ? `Hey ${profile.first_name} — Welcome to Scramble!`
            : 'Hi there! Nice to see you!'}
        </div>
      </div>

      {profile.country_code === 'US' &&
        status.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className={s.amount_warning} />
        )}

      <NotificationBlock
        className={s.notifications}
        notifications={notifications}
      />

      <section className={s.summary}>
        <BalanceInfo balance={balance} />
        <IncomeBlock earnings={earnings} />
        <HappyFounders founders={happyFounders} />
      </section>

      <section className={s.investing}>
        <h3 className={classnames(s.investing__title, 'f-26 f-500')}>
          Your investment settings
        </h3>
        <InvestingInfo
          status={status}
          onLoansEdit={() =>
            history.push(APP_LINKS.investing + '?to=funds-allocation')
          }
          onStrategyEdit={() =>
            history.push(APP_LINKS.investing + '?to=strategy-selection')
          }
          isDashboard
          className={classnames(
            s.investing__summary,
            !isActiveFR &&
              status.step !== 5 &&
              (!status.auto_invest ||
                !status.earmarked ||
                status.earmarked <= 25) &&
              'hidden',
            (isActiveFR || status.step === 4) &&
              (status.option === STRATEGIES.not_selected ||
                !status.earmarked ||
                status.earmarked <= 25 ||
                !status.has_accepted_necessary_terms) &&
              'hidden'
          )}
        />
        <RoundLink
          label="Go to investing"
          path={APP_LINKS.investing}
          className={s.investing__loan}
          fullWidth
          fillBackground
        />
        {status.step === 5 && hasLoans && (
          <RoundLink
            label="See new loans"
            path={APP_LINKS.loans}
            className={s.investing__loan}
            fullWidth
          />
        )}
      </section>

      <section className={s.baskets}>
        <div className={s.baskets__top}>
          <h2 className="f-26 f-500">Your loan groups</h2>
          <RoundLink
            label="Learn more"
            path={APP_LINKS.helpLoanTerms()}
            className={s.baskets__link}
          />
        </div>
        <div className={s.baskets__cards}>
          {loanGroups.data &&
            loanGroups.data.map(basket => (
              <LoanBasket
                key={basket.id}
                data={basket}
                isActiveFundraising={isActiveFR}
              />
            ))}
        </div>
        <RoundLink
          label="Learn more"
          path={APP_LINKS.helpLoanTerms()}
          className={s.baskets__link_bottom}
          fullWidth
        />
      </section>

      <section className={s.affiliate}>
        <h2 className="f-26 f-500">
          Earn exclusive rewards when your friends invest
        </h2>
        <AffiliateSteps className={s.affiliate__steps} />
        <RoundLink
          label="Invite friends"
          path={APP_LINKS.affiliate}
          className={s.affiliate__invite}
          fullWidth
          fillBackground
        />
      </section>
    </>
  );
};
