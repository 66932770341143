import React from 'react';
import classnames from 'classnames';
import {
  isExpiredResetDate,
  resetInvestingExpireDate,
} from '../../../../helpers/date';
import { useGetAllocationStatus } from '../../../../hooks/useGetAllocationStatus';
import s from './index.module.scss';

export const ResetInvesting = ({ status, className, showAlways, onReset }) => {
  const { allocatedGroupA, allocatedGroupB } = useGetAllocationStatus();

  if (!status) return null;

  if (status.editable_until && isExpiredResetDate(status.editable_until))
    return null;

  if (!allocatedGroupA && !allocatedGroupB && !showAlways) return null;

  const showDate = status.has_accepted_necessary_terms;

  return (
    <div className={classnames(s.wrapper, className)}>
      <div>
        <h2 className="f-26 f-500">Changed your mind?</h2>
        <p className="f-16 f-400">
          {showDate
            ? `You can decide not to participate in the round before ${resetInvestingExpireDate(
                status.editable_until,
                status.end_date
              )}`
            : 'You can decide not to participate in the round'}
        </p>
      </div>
      <button type="button" className="f-16 f-400 hov" onClick={onReset}>
        Reset investing
      </button>
    </div>
  );
};
