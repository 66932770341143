import React, { Component } from 'react';
import { getHappyFoundersLatestFR } from '../../../store/services/investingServices';
import { StartupVerticalCard } from '../../../components/Cards/StartupVerticalCard';
import { ReactComponent as IconHeart } from '../../../assets/icons/icon_heart.svg';

class InvHappyStartups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startups: null,
    };
  }

  componentDidMount() {
    const { page, limit } = this.state;
    getHappyFoundersLatestFR({ page, limit }).then(
      res =>
        res &&
        res.success &&
        this.setState(prevState => ({
          ...prevState,
          startups: res.data,
        }))
    );
  }

  render() {
    const { startups } = this.state;
    const isEmpty = !startups || !(startups && startups.list.length);

    return (
      <div className="inv_happy_startups">
        <h2 className="inv_happy_startups__title f-26 f-500">
          <span>Thank you for helping these brands grow their future</span>
          <IconHeart />
        </h2>
        {isEmpty ? (
          <div className="inv_happy_startups__empty f-16 f-400">
            No startups
          </div>
        ) : (
          <div className="inv_happy_startups__list">
            {startups.list.map(startup => (
              <StartupVerticalCard key={startup.id} startup={startup} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default InvHappyStartups;
