import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getLoansByFundraisingID } from '../../../store/actions/loanActions';
import { prettyFloatMoney } from '../../../helpers/utils';
import s from './index.module.scss';

export const LoanDetails = ({ fundraisingID }) => {
  const dispatch = useDispatch();
  const { loansByFundraisingID } = useSelector(state => state.loanStore);

  useEffect(() => {
    dispatch(getLoansByFundraisingID(fundraisingID));
  }, [fundraisingID]);

  const loans = loansByFundraisingID.data || [];

  if (!loans.length) {
    return null;
  }

  return (
    <div className={s.details}>
      <h2 className="f-26 f-500">Loan details</h2>

      <div className={s.table_wrapper}>
        <div className={s.table_inner}>
          <div className={s.table}>
            <div className={classnames(s.thead)}>
              <div className={s.thead_col}>Loan group</div>
              <div className={s.thead_col}>Loan amount</div>
              <div className={s.thead_col}>Outstanding loan</div>
              <div className={s.thead_col}>Flat fee accrued</div>
              <div className={s.thead_col}>Flat fee paid</div>
            </div>

            <div className={s.tbody}>
              {loans.map((loan, idx) => (
                <div className={classnames(s.tbody_row)} key={idx}>
                  <div className={s.tbody_col}>{loan.title}</div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.total_loan_amount)}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.total_remain_amount)}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.flat_fee_accrued)}
                  </div>
                  <div className={s.tbody_col}>
                    {prettyFloatMoney(loan.loans.flat_fee_paid)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
